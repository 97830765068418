import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Tag } from 'primereact/tag'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Card, CardHeader, Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { dataTransactionByDates, dataTransactions, getAllDataTransaction, resetDataTransactions, } from '../../../features/DataTransaction/DataTransactionSlice'
import { toast } from 'react-toastify'
import { getUser, logout } from '../../../features/Auth/AuthSlice'
import { DatePicker } from 'antd'
import axios from 'axios'
import { baseApiUrl } from '../../../Utils/constants'
import Spinner from '../../../Component/Spinner'


const Data_Transactions = () => {
  const { RangePicker } = DatePicker
  const dispatch = useDispatch()
  const { user } = useSelector(getUser)
  const [dates, setDates] = useState([])
  const [tableValue, setTableValue] = useState([])
  const [loading, setLoading] = useState(false)
  const { dataTransaction, isErrorDataTranxn, isSuccessDataTranxn, isLoadingDataTranxn, messageDataTranxn, dataTransactionNetwork } = useSelector(dataTransactions)
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"
  const nairaSign = '₦'
  const transaction_complete = 'completed'
  const transaction_pending = 'pending'
  const transaction_failed = 'failed'
  const searchDatesApi = "/api/data/data-transactions-by-dates"

  const dataPlanFormat = (dataTransaction) => {
    if (dataTransaction?.dataPlan.type === "sme") {
      if (dataTransaction?.dataPlan?.validity > 1) {
        return dataTransaction?.dataPlan?.volume + dataTransaction?.dataPlan?.unit + ' for ' + dataTransaction?.dataPlan?.validity + 'days'
      } else {
        return dataTransaction?.dataPlan?.volume + dataTransaction?.dataPlan?.unit + ' for ' + dataTransaction?.dataPlan?.validity + 'day'
      }
    }
    else {
      return dataTransaction?.dataPlan.planDescription
    }
  }
  const dataTypeFormat = (dataTransaction) => {
    if (dataTransaction?.dataPlan.type) {
      return dataTransaction?.dataPlan.type
    }
  }

  const amountDataPlanFormat = (dataTransaction) => {
    if (dataTransaction.amount === undefined) {
      return nairaSign + dataTransaction?.dataPlan?.amount
    } else {
      return nairaSign + dataTransaction?.amount
    }
  }

  const dateFormat = (date) => {
    const dateFromMongoDB = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour clock format
    };

    const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

    return formatted
  }

  const dateFormatTemplate = (dataTransaction) => {
    return dateFormat(dataTransaction?.createdAt)
  }


  const numberFormat = (number) => {
    if (number?.startsWith("234")) {
      return '0' + number?.substring(3)
    } else {
      return number
    }
  }

  const numberFormatTemplate = (dataTransaction) => {
    return numberFormat(dataTransaction?.msisdn)
  }

  const statusBodyTemplate = (dataTransaction) => {
    return <Tag value={dataTransaction.status} severity={getSeverity(dataTransaction)}></Tag>;
  };

  const getSeverity = (dataTransaction) => {
    switch (dataTransaction.status) {
      case transaction_complete:
        return 'success';

      case transaction_pending:
        return 'warning';

      case transaction_failed:
        return 'danger';

      default:
        return null;
    }
  };

  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  //calculate total data
  const totalData = tableValue?.filter((transaction) => transaction.status === transaction_complete)?.reduce((sum, transaction) => {
    const { volume, unit } = transaction.dataPlan

    let standardVolume = volume
    if (unit === 'mb') {
      standardVolume /= 1024
    }

    return sum + standardVolume
  }, 0)



  //calculate total amount
  const totalAmount = tableValue?.filter((transaction) => transaction.status === transaction_complete)?.reduce((sum, transaction) => transaction.amount === undefined ? sum + transaction.dataPlan.amount : sum + transaction.amount, 0)

  //search date button
  const searchDates = async () => {
    if (dates === null) {
      toast.error('Please Select dates')
    } else {
      const formData = { startDate: dates[0], endDate: dates[1] }
      try {
        const headers = {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'

        };
        setLoading(true)
        const response = await axios.post(baseApiUrl + searchDatesApi, formData, { headers })
        if (response.data) {
          dispatch(dataTransactionByDates(response.data))
        }
      } catch (error) {
        if (error.response.data !== errorTokenExpired) {
          toast.error(error.response.data)
        } else {
          dispatch(logout())
          toast.error('Error!! Please Login')
        }
      } finally {
        setLoading(false)
      }
    }
  }
  // console.log(dataTransaction)

  //change in data transaction network
  useEffect(() => {
    dispatch(getAllDataTransaction(user?._id))
    if (dataTransactionNetwork === '/user-glo-transactions') {
      const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'glo')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === '/user-mtn-transactions') {
      const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'mtn')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === '/user-airtel-transactions') {
      const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'airtel')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === "/user-9mobile-transactions") {
      const datatranx = dataTransaction?.filter(data => data.dataPlan.network === '9mobile')
      setTableValue(datatranx)
    }

    dispatch(resetDataTransactions())
  }, [dataTransactionNetwork])

  useEffect(() => {
    if (dataTransactionNetwork === '/user-glo-transactions') {
      const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'glo')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === '/user-mtn-transactions') {
      const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'mtn')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === '/user-airtel-transactions') {
      const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'airtel')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === "/user-9mobile-transactions") {
      const datatranx = dataTransaction?.filter(data => data.dataPlan.network === '9mobile')
      setTableValue(datatranx)
    }
    dispatch(resetDataTransactions())
  }, [dataTransaction])

  useEffect(() => {
    if (messageDataTranxn === errorTokenExpired || messageDataTranxn === errorTokenInvalid) {
      toast.error('Error!! Please Login again')
      dispatch(logout())
    } else if (messageDataTranxn !== '') {
      toast.error(messageDataTranxn)
    }

    if (isSuccessDataTranxn || isErrorDataTranxn) {
      dispatch(resetDataTransactions())
    }
  }, [isSuccessDataTranxn, isErrorDataTranxn])

  return (
    <div>
      <Helmet defer={false}>
        <title>Fliprecharge || Data Transactions</title>
      </Helmet>
      {loading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        <Card className="shadow px-3 pb-4">
          <CardHeader className="bg-transparent">
            <h3 className="mb-0">Data Transactions</h3>
          </CardHeader>
          <div className='d-md-flex justify-content-between mt-5'>
            <RangePicker
              className='w-75 w-md-100 my-2 me-5'
              onChange={(values) =>
                setDates(values?.map(item =>
                  item.format("YYYY-MM-DD")
                ))
              }
            />
            <button onClick={searchDates} disabled={!dates} className='btn btn-primary w-25 my-2' style={{ minWidth: "100px" }} type='submit'>Search</button>
          </div>
          <div className='d-md-flex mt-2 text-center'>
            <div className='alert alert-primary fs-5'>Total Data: {totalData.toFixed(2) > 1024 ? (totalData / 1024).toFixed(2) : totalData.toFixed(2)}{totalData.toFixed(2) > 1024 ? 'TB' : 'GB'}</div>
            <div className='alert alert-primary mx-md-4 fs-5'>Total Amount: ₦{totalAmount.toFixed(2)}</div>
          </div>
          {isLoadingDataTranxn ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search' className='searchdrop p-2 border w-50 my-2' onInput={(e) => setFilter({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS }, })} />
            <DataTable value={tableValue} filters={filter} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
              <Column field='createdAt' header='Date' body={dateFormatTemplate} style={{ width: '30%' }}></Column>
              <Column field='dataPlan' header='Data Plan' body={dataPlanFormat} style={{ width: '30%' }}></Column>
              <Column field='dataPlan' header='Data Type' body={dataTypeFormat} style={{ width: '30%' }}></Column>
              <Column field='dataPlan' header='Amount' body={amountDataPlanFormat}></Column>
              <Column field='msisdn' header='Mobile Number' body={numberFormatTemplate} style={{ width: '30%' }}></Column>
              <Column field='status' header='Status' body={statusBodyTemplate}></Column>
            </DataTable></div>}
        </Card>
      </Container>
    </div>
  )
}

export default Data_Transactions