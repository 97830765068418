/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.js";

import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../../../features/Auth/AuthSlice.jsx";
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Tag } from 'primereact/tag'
import axios from 'axios';
import { baseApiUrl } from '../../../Utils/constants';
import { toast } from "react-toastify";
import { allUsers, getAllUsers, resetAllUsers } from "../../../features/Users/UsersSlice.jsx";
import * as XLSX from 'xlsx'; // Import the xlsx package

const Wallet_Transactions = () => {
  const { user } = useSelector(getUser)
  const { AllUsers, isSuccessAllUsers, isErrorAllUsers, isMessageAllUsers } = useSelector(allUsers)
  const dispatch = useDispatch()
  const [totalCurrentBalance, setTotalCurrentBalance] = useState(0);

  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [walletTransactions, setWalletTransactions] = useState([])
  const [loading, setLoading] = useState(false)


  const getUsername = (walletId) => {
    const username = AllUsers.find(user => user._id === walletId?.walletId.user)
    return username?.username
  }

  const amountFormat = (wallet) => {
    return '₦' + wallet?.amount?.toFixed(2)
  }

  const currentBalanceFormat = (wallet) => {
    return '₦' + wallet?.currentbalance?.toFixed(2)
  }

  const dateFormat = (date) => {
    const dateFromMongoDB = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour clock format
    };

    const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

    return formatted
  }

  const dateFormatTemplate = (walletTransactions) => {
    return dateFormat(walletTransactions?.timestamp)
  }

  const transactionTypeBodyTemplate = (walletHistory) => {
    return <Tag value={walletHistory.transactionType} severity={getSeverity(walletHistory)}></Tag>;
  };

  const getSeverity = (walletHistory) => {
    switch (walletHistory?.transactionType) {
      case 'Funding':
        return 'success';

      case 'Withdrawal':
        return 'danger';

      default:
        return null;
    }
  };

  useEffect(() => {
    const getWalletTransactions = async () => {
      try {
        setLoading(true)
        const headers = {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'

        };
        const response = await axios.get(baseApiUrl + '/api/wallet/get-all-wallet-histories', { headers })
        if (response.data) {
          setWalletTransactions(response.data)
        }
      } catch (error) {
        if (error.response.data === 'token expired') {
          toast.error('Error!! Please Login again')
          dispatch(logout())
        } else if (error.response) {
          toast.error(error.response.data)
        } else {
          toast.error(error)
        }
      } finally {
        setLoading(false)
      }
    }

    getWalletTransactions()
    dispatch(getAllUsers())

  }, [])

  useEffect(() => {
    if (isMessageAllUsers === 'token expired' || isMessageAllUsers === 'Token is invalid') {
      toast.error('Error!! Please Login again')
      dispatch(logout())
    } else if (isMessageAllUsers !== '') {
      toast.error(isMessageAllUsers)
    }

    if (isSuccessAllUsers || isErrorAllUsers) {
      dispatch(resetAllUsers())
    }
  }, [isMessageAllUsers, isSuccessAllUsers])

  useEffect(() => {
    const total = walletTransactions.reduce((acc, curr) => acc + parseFloat(curr.currentbalance || 0), 0);
    setTotalCurrentBalance(total);
  }, [walletTransactions]);

  // Function to export table data to Excel
  const exportToExcel = () => {
    debugger;
    const worksheet = XLSX.utils.json_to_sheet(walletTransactions.map(tx => ({
      Date: dateFormat(tx.timestamp),
      User: getUsername(tx),
      Amount: tx.amount,
      'Transaction Type': tx.transactionType,
      'Wallet Balance': tx.currentbalance
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Wallet Transactions");
    XLSX.writeFile(workbook, "Wallet_Transactions.xlsx");
  }
  return (
    <>
      <Helmet defer={false}>
        <title>Fliprechargee || Wallet Transactions</title>
      </Helmet>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow px-3 pb-4">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">All Wallet Transactions</h3>
                <button className="btn btn-success my-2" onClick={exportToExcel}>Export to Excel</button>
              </CardHeader>
              <div className="">
                {/* <div className="alert alert-info">
                 <h4 className="text text-white">Total Wallet Balance: ₦{totalCurrentBalance.toFixed(2)}</h4>  
               </div> */}
                {loading ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search' className='searchdrop p-2 border w-25 my-4' onInput={(e) => setFilter({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS }, })} />

                  <DataTable value={walletTransactions} filters={filter} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                    <Column field="timestamp" header='Date' body={dateFormatTemplate} style={{ width: '20%' }}></Column>
                    <Column field="walletId" header='User' body={getUsername} style={{ width: '20%' }}></Column>
                    <Column field="amount" header='Amount' body={amountFormat}></Column>
                    <Column field="transactionType" header='Transaction Type' body={transactionTypeBodyTemplate} style={{ width: '20%' }}></Column>
                    <Column field="currentbalance" header='Wallet Balance' body={currentBalanceFormat} style={{ width: '20%' }}></Column>
                  </DataTable></div>}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>)
};

export default Wallet_Transactions;
