import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";
import { Helmet } from 'react-helmet-async'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { useDispatch, useSelector } from 'react-redux';
import { allUsers, getAllUsers, resetAllUsers } from '../../../features/Users/UsersSlice';
import { toast } from 'react-toastify';
import { getUser, logout } from '../../../features/Auth/AuthSlice';
import axios from 'axios';
import { baseApiUrl } from '../../../Utils/constants';
import Spinner from '../../../Component/Spinner';

const All_Users = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const [loadingWallet, setLoadingWallet] = useState(false)
    const [allUserWallet, setAllUserWallet] = useState([])
    const [totalWalletBalance, setTotalWalletBalance] = useState(0);

    const [filter, setFilter] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    })

    const { AllUsers, isLoadingAllUsers, isSuccessAllUsers, isErrorAllUsers, isMessageAllUsers } = useSelector(allUsers)

    const dateFormat = (date) => {
        const dateFromMongoDB = new Date(date);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true, // Use 12-hour clock format
        };

        const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

        return formatted
    }

    const dateFormatTemplate = (users) => {
        return dateFormat(users?.createdAt)
    }

    const walletBalanceFormat = (userId) => {
        const eachUserWallet = allUserWallet?.find(wallet => wallet?.user === userId._id);
        const balance = eachUserWallet?.balance || 0;
        return `₦${new Intl.NumberFormat('en-US').format(balance.toFixed(2))}`;
    };


    useEffect(() => {
        dispatch(getAllUsers())
        const getAllWallet = async () => {
            try {
                setLoadingWallet(true)
                const headers = {
                    'Authorization': `Bearer ${user?.accessToken}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(baseApiUrl + "/api/wallet/get-all-wallet", { headers })
                if (response.data) {
                    setAllUserWallet(response.data)
                }
            } catch (error) {
                if (error.response.data === 'token expired' || error.response.data === 'Token is invalid') {
                    dispatch(logout())
                } else if (error.response) {
                    toast.error(error.response.data)
                } else {
                    toast.error(error)
                }

            } finally {
                setLoadingWallet(false)
            }
        }
        getAllWallet()
    }, [])

    useEffect(() => {
        if (isMessageAllUsers === 'token expired' || isMessageAllUsers === 'Token is invalid') {
            toast.error('Error!! Please Login again')
            dispatch(logout())
        } else if (isMessageAllUsers !== '') {
            toast.error(isMessageAllUsers)
        }

        if (isSuccessAllUsers || isErrorAllUsers) {
            dispatch(resetAllUsers())
        }

    }, [isSuccessAllUsers, isErrorAllUsers])

    useEffect(() => {
        const totalBalance = allUserWallet.reduce((acc, wallet) => acc + (wallet.balance || 0), 0);
        setTotalWalletBalance(totalBalance);
    }, [allUserWallet]);

    return (
        <div>
            <Helmet defer={false}>
                <title>Fliprecharge || All Users</title>
            </Helmet>
            {loadingWallet && <Spinner />}
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
            {/* Page content */}
            <Container className="" style={{ marginTop: "-22rem" }} fluid>
                {/* Table */}
                <Card className="shadow px-3 pb-4">
                    <CardHeader className="bg-transparent">
                        <h3 className="mb-0">All Registered Users</h3>
                    </CardHeader>
                    {isLoadingAllUsers ? <div className='alert alert-light mt-2'>......Loading</div> :

                        <div>
                            <div>
                                <div className="alert alert-info">
                                    <h4 >    Total Wallet Balance: ₦{new Intl.NumberFormat('en-US').format(totalWalletBalance.toFixed(2))}
                                     </h4>
                                </div>
                            </div>
                            <div>
                                <InputText placeholder='search' className='searchdrop p-2 border w-25 my-4' onInput={(e) => setFilter({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS }, })} />

                                <DataTable value={AllUsers} filters={filter} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                                    <Column field='createdAt' header='Date' body={dateFormatTemplate} style={{ width: '20%' }}></Column>
                                    <Column field='fullname' header='Fullname' style={{ width: '25%' }}></Column>
                                    <Column field='username' header='Username' style={{ width: '15%' }}></Column>
                                    <Column field='email' header='Email' style={{ width: '20%' }}></Column>
                                    <Column field='_id' header='Wallet Balance' body={walletBalanceFormat} style={{ width: '10%' }}></Column>
                                    <Column field='phonenumber' header='Phone Number' style={{ width: '20%' }}></Column>
                                    <Column field='isAdmin' header='Admin' ></Column>
                                </DataTable>

                            </div>

                        </div>

                    }
                </Card>
            </Container>
        </div>
    )
}

export default All_Users