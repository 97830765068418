import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Tag } from 'primereact/tag'
import { useDispatch, useSelector } from 'react-redux'
import { allUsers, getAllUsers } from '../../../features/Users/UsersSlice'
import { dataTransactions, getAllDataTransaction, resetDataTransactions } from '../../../features/DataTransaction/DataTransactionSlice'
import { Input, Space } from 'antd';
import { Helmet } from 'react-helmet-async';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import Spinner from '../../../Component/Spinner';
import { toast } from 'react-toastify'
import { logout } from '../../../features/Auth/AuthSlice'
import * as XLSX from 'xlsx';

const { Search } = Input;

const UserDataTransactions = () => {
  const dispatch = useDispatch()
  const { AllUsers, isLoadingAllUsers, isSuccessAllUsers, isErrorAllUsers, isMessageAllUsers } = useSelector(allUsers)
  const { dataTransaction, isErrorDataTranxn, isSuccessDataTranxn, isLoadingDataTranxn, messageDataTranxn, dataTransactionNetwork } = useSelector(dataTransactions)
  const [userId, setUserId] = useState("")
  const [tableValue, setTablevalue] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('');



  const filteredData = tableValue?.filter((data) => {
    const searchText = `${data?.dataPlan?.network} ${data?.amount}`.toLowerCase()
    return searchText.includes(globalFilterValue.toLowerCase())
  })

  const getUsername = (userId) => {
    const User = AllUsers?.find(username => username?._id === userId?.user)
    return User?.username
  }

  const networkFormat = (dataplan) => {
    return dataplan?.dataPlan?.network
  }

  const amountDataPlanFormat = (dataTransaction) => {
    if (dataTransaction.amount === undefined) {
      return '₦' + dataTransaction?.dataPlan?.amount
    } else {
      return '₦' + dataTransaction?.amount
    }
  }

  const dataPlanFormat = (dataTransaction) => {
    if (dataTransaction?.dataPlan.type === "sme") {
      if (dataTransaction?.dataPlan?.validity > 1) {
        return dataTransaction?.dataPlan?.volume + dataTransaction?.dataPlan?.unit + ' for ' + dataTransaction?.dataPlan?.validity + 'days'
      } else {
        return dataTransaction?.dataPlan?.volume + dataTransaction?.dataPlan?.unit + ' for ' + dataTransaction?.dataPlan?.validity + 'day'
      }
    }
    else {
      return dataTransaction?.dataPlan.planDescription
    }
  }

  const dateFormat = (date) => {
    const dateFromMongoDB = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour clock format
    };

    const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

    return formatted
  }

  const dateFormatTemplate = (dataTransaction) => {
    return dateFormat(dataTransaction?.createdAt)
  }

  const numberFormat = (number) => {
    if (number?.startsWith("234")) {
      return '0' + number?.substring(3)
    } else {
      return number
    }
  }



  const numberFormatTemplate = (dataTransaction) => {
    return numberFormat(dataTransaction?.msisdn)
  }

  const statusBodyTemplate = (dataTransaction) => {
    return <Tag value={dataTransaction.status} severity={getSeverity(dataTransaction)}></Tag>;
  };

  const getSeverity = (dataTransaction) => {
    switch (dataTransaction.status) {
      case 'completed':
        return 'success';

      case 'pending':
        return 'warning';

      case 'failed':
        return 'danger';

      default:
        return null;
    }
  };


  //get users
  useEffect(() => {
    dispatch(getAllUsers())
  }, [])

  //search usernames
  const onSearch = (value) => {
    console.log(value.trim().toLowerCase())
    if (AllUsers) {
      let userDetails = AllUsers?.find((data) => data?.username === value.trim().toLowerCase())
      if (userDetails) {
        setUserId(userDetails?._id)
      } else {
        setTablevalue([])
        toast.error("Input a valid username")
      }
    }
  }

  //call api to get user data transactions
  useEffect(() => {
    if (userId) {
      dispatch(getAllDataTransaction(userId))
    }
  }, [userId])

  useEffect(() => {
    if (isErrorDataTranxn) {
      setTablevalue([])
    }
    if (isSuccessDataTranxn) {
      setTablevalue(dataTransaction)
    }
    if (messageDataTranxn === 'token expired' || messageDataTranxn === "Token is invalid") {
      toast.error('Error!! Please Login again')
      dispatch(logout())
    } else if (messageDataTranxn !== '') {
      toast.error(messageDataTranxn)
    }

    if (isSuccessDataTranxn || isErrorDataTranxn) {
      dispatch(resetDataTransactions())
    }

  }, [isSuccessDataTranxn, isErrorDataTranxn])


  const exportToExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert filteredData to a worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData.map(item => ({
      Date: dateFormat(item.createdAt),
      User: getUsername(item.user),
      'Data Plan': dataPlanFormat(item),
      Network: networkFormat(item.dataPlan),
      Amount: amountDataPlanFormat(item),
      'Mobile Number': numberFormat(item.msisdn),
      Status: item.status,
    })));

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "DataTransactions");

    // Write the workbook and initiate download
    XLSX.writeFile(wb, "UserDataTransactions.xlsx");
  };
  // console.log(dataTransaction)
  // console.log(dataTransactionNetwork)
  // console.log(userId)
  // console.log(AllUsers)


  return (
    <div>
      <Helmet defer={false}>
        <title>Fliprecharge || All Data Transactions</title>
      </Helmet>
      {isLoadingAllUsers && <Spinner />}
      {isLoadingDataTranxn && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        <div className='m-3'><Search placeholder='Enter Username' onChange={() => setUserId("")} onSearch={onSearch} style={{ width: 200, }} /></div>

        <Row>
          <div className="col">
            <Card className="shadow px-3 pb-4">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">User Data Transactions</h3>
              </CardHeader>
              <div>
                {isLoadingDataTranxn ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search network' className='searchdrop p-2 border w-25 my-4' value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)} />
                  <button className='btn btn-success left-3' onClick={exportToExcel}>Export to Excel</button>

                  <DataTable value={filteredData} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                    <Column header='Date' field='createdAt' body={dateFormatTemplate} ></Column>
                    <Column header='User' field='user' body={getUsername} ></Column>
                    <Column header='Data Plan' field='dataPlan' body={dataPlanFormat} ></Column>
                    <Column header='Network' field='dataPlan' body={networkFormat} ></Column>
                    <Column header='Amount' field='dataPlan' body={amountDataPlanFormat}></Column>
                    <Column header='Mobile Number' field='msisdn' body={numberFormatTemplate}></Column>
                    <Column header='Status' field='status' body={statusBodyTemplate}></Column>
                  </DataTable></div>}
              </div>
            </Card>
          </div>
        </Row>
      </Container>

    </div>
  )
}

export default UserDataTransactions