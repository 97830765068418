import { DataTable } from 'primereact/datatable'
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import React, { useEffect, useState } from 'react'
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag'
import {InputText} from 'primereact/inputtext'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserPayments, resetUserPayment, userPayments } from '../../../features/Payments/UserPaymentSlice';
import { toast } from 'react-toastify';
import { logout } from '../../../features/Auth/AuthSlice';
import { Helmet } from 'react-helmet-async';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import Spinner from '../../../Component/Spinner';

const Payments = () => {
  const [selectedPayments, setSelectedPayments] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userPayment, isLoadingUserPayment, isErrorUserPayment, isSuccessUserPayment, isMessageUserPayment} = useSelector(userPayments)


  useEffect(()=> {
    dispatch(getUserPayments())
  }, [])


  useEffect(()=> {
    if(isMessageUserPayment === 'token expired' || isMessageUserPayment === "Token is invalid"){
      toast.error('Error!! Please Login')
      dispatch(logout())
    } else if(isMessageUserPayment !== '') {
      toast.error(isMessageUserPayment)
    }

    if(isSuccessUserPayment || isErrorUserPayment){
      dispatch(resetUserPayment())
    }

  }, [isSuccessUserPayment, isErrorUserPayment])

  const filteredData = userPayment?.filter((data)=> {
    const searchText = `${data?.paymentDetails?.paymentType} ${data?.paymentType} ${data?.paymentDetails?.amount} ${data?.amount}`.toLowerCase()
    return searchText.includes(globalFilterValue.toLowerCase())
  })

  const dateFormat = (date) => {
    const dateFromMongoDB = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour clock format
    };

    const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

    return formatted
}

const dateFormatTemplate = (paymentDetails)=> {
    return dateFormat(paymentDetails?.createdAt)
}

  const transactionTypeTemplate =(data)=> {
    return data?.paymentDetails?.paymentType?.toUpperCase() || data?.paymentType?.toUpperCase()
  }

  const amountFormat = (paymentDetails)=> {
    if(paymentDetails?.paymentDetails){
      return '₦' + paymentDetails?.paymentDetails?.amount
    } else {
      return '₦' + paymentDetails?.amount
    }
  }

  const statusBodyTemplate = (status) => {
    return <Tag value={status?.paymentDetails?.status || status?.status} severity={getSeverity(status)}></Tag>;
  };

  const getSeverity = (status)=> {
    switch(status?.paymentDetails?.status || status?.status){
       case 'completed':
            return 'success';
  
        case 'pending':
            return 'warning';

        case 'failed':
            return 'danger';
  
        default:
            return null;
    }
  }
  const selectChange = (e)=> {
    navigate(`/user/payment-details/${e._id}`)
  }

  return (
    <div>
      <Helmet defer={false}>
        <title>Telecom || Payments</title>
      </Helmet>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{height : "70vh"}}></div>
      {isLoadingUserPayment && <Spinner />}
      <Container className="" style={{marginTop: "-22rem"}} fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">All Payments</h3>
              </CardHeader>
              <div>
              <InputText placeholder='amount or transaction type' className='searchdrop ml-2 p-2 border w-50 my-2'  value={globalFilterValue}
            onChange={(e) => setGlobalFilterValue(e.target.value)} />
              <DataTable value={filteredData} responsiveLayout='scroll' selectionMode="single" selection={selectedPayments} onSelectionChange={(e) => selectChange(e.value)} showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                <Column field='createdAt' header='Date' body={dateFormatTemplate} ></Column>
                <Column field='' header='Transaction Type' body={transactionTypeTemplate}></Column>
                <Column field='' header='Amount' body={amountFormat}></Column>
                <Column field='' header='Status' body={statusBodyTemplate}></Column>
              </DataTable>               
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Payments