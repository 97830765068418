import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, CardBody, CardHeader, Row } from 'reactstrap'
import { logout } from '../../../features/Auth/AuthSlice'
import { allPayments, getAllPayment, resetAllPayment } from '../../../features/Payments/AllPaymentSlice'

const All_Payments_Details = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const [paymentDetails, setPaymentDetails]= useState([])
    const { allPayment, isLoadingAllPayment, isErrorAllPayment, isSuccessAllPayment, isMessageAllPayment } = useSelector(allPayments)

    const dateFormat = (date) => {
      const dateFromMongoDB = new Date(date);
  
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Use 12-hour clock format
      };
  
      const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);
  
      return formatted
  }

    useEffect(()=> {
      dispatch(getAllPayment())
    }, [id])

    useEffect(()=> {
      if(isMessageAllPayment === 'token expired' || isMessageAllPayment === "Token is invalid"){
        toast.error('Error!! Please Login')
        dispatch(logout())
      } else if(isMessageAllPayment !== '') {
        toast.error(isMessageAllPayment)
      }

      if(isSuccessAllPayment){
        const details = allPayment?.filter((payment)=> payment?._id === id)
        setPaymentDetails(details)
      }

      dispatch(resetAllPayment())

    }, [isSuccessAllPayment, isErrorAllPayment])

    const paymentDisplay = paymentDetails.map(eachDetail=> {
      if(eachDetail?.paymentDetails){
        return (
          <div>
            <div className='d-flex my-4'>
              <h3 className='col-4'>Date: {dateFormat(eachDetail?.paidOn)}</h3>
              <h3 className='col-5'>Transaction Reference: {eachDetail?.transactionReference}</h3>
              <h3 className='col-3'>Payment Status: {eachDetail?.paymentStatus}</h3>
            </div>
            <div className='d-flex my-4'>
              <h3 className='col-4'>Payment Method: {eachDetail?.paymentMethod}</h3>
              <h3 className='col-5'>Payment Type: {eachDetail?.productType}</h3>
              
            </div>
            <div className='d-flex my-4'>
              <h3 className='col-4'>Senders Bank Name: {eachDetail?.paymentSourceInformation?.map((source)=> source?.accountName)}</h3>
              <h3 className='col-5'>Senders Account Number: {eachDetail?.paymentSourceInformation?.map((source)=> source?.accountNumber)}</h3>
              <h3 className='col-3'>Amount Sent: ₦{eachDetail?.amountPaid}</h3>
            </div>
            <div className='d-flex my-4'>
              <h3 className='col-4'>Receivers Bank Name: {eachDetail?.destinationAccountInformation?.bankName}</h3>
              <h3 className='col-5'>Receivers Account Number: {eachDetail?.destinationAccountInformation?.accountNumber}</h3>
              <h3 className='col-3'>Amount Received: ₦{eachDetail?.settlementAmount} </h3>
            </div>
          </div>
        )
      } else if(eachDetail?.paymentType === 'transfer') {
        return (
          <div>
            <div className='d-flex my-4'>
              <h3 className='col-4'>Date: {dateFormat(eachDetail?.createdAt)}</h3>
              <h3 className='col-5'>Transaction Reference: {eachDetail?.reference}</h3>
              <h3 className='col-3'>Payment Status: {eachDetail?.status}</h3>
            </div>
            <div className='d-flex my-4'>
              <h3 className='col-4'>Payment Type: {eachDetail?.paymentType.toUpperCase()}</h3>
              <h3 className='col-5'>Amount Sent: ₦{eachDetail?.amount}</h3>
              <h3 className='col-3'>Amount Received: ₦{eachDetail?.amount - 50}</h3>
            </div>
          </div>
        )
      } else if (eachDetail?.paymentType === 'sdk'){
        return (
          <div>
            <div className='d-flex my-4'>
              <h3 className='col-4'>Date: {dateFormat(eachDetail?.createdAt)}</h3>
              <h3 className='col-5'>Transaction Reference: {eachDetail?.reference}</h3>
              <h3 className='col-3'>Payment Status: {eachDetail?.status}</h3>
            </div>
            <div className='d-flex my-4'>
              <h3 className='col-4'>Payment Type: {eachDetail?.paymentType.toUpperCase()}</h3>
              <h3 className='col-5'>Amount Sent: ₦{eachDetail?.amount}</h3>
            </div>
          </div>
        )
      }
    })


  return (
    <div>
      <Helmet defer={false}>
        <title>Fliprecharge || Payment Details</title>
      </Helmet>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "30vh"}}></div>
      <Row >
        <div className='col mt--6'>
          <Card className='shadow'>
            <CardHeader>
              <h3>Payment Details</h3>
            </CardHeader>
            <CardBody className='bg-gradient-info'>
              <h3>{paymentDisplay}</h3>
            </CardBody>
          </Card>
        </div>
      </Row>
    </div>
  )
}

export default All_Payments_Details