import axios from "axios";
import { baseApiUrl } from "../../Utils/constants";

const API = `${baseApiUrl}/api/data`

const findAllDataTransaction = async(accesstoken, userId)=> {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`
        }
    }
    const response = await axios.get(`${API}/get-data-transactions/${userId}`, config)
    return response.data
}

const DataTransactionService = {
    findAllDataTransaction
}

export default DataTransactionService