/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.js";
import { Button, Form, Input, Select, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../../../features/Auth/AuthSlice.jsx";
import { dataPackage, getAllDataPlan, resetDataPlan } from "../../../features/DataPlan/DataPlanSlice.jsx";
import { toast } from "react-toastify";
import axios from "axios";
import { baseApiUrl } from "../../../Utils/constants.js";
import { updateWalletBalance, walletBalance } from "../../../features/Wallet/WalletSlice.jsx";
import Spinner from "../../../Component/Spinner.jsx";
import ReactGA from 'react-ga';





const Buy_Data = () => {
  const dispatch = useDispatch()
  const [copiedText, setCopiedText] = useState();
  const { user } = useSelector(getUser)
  const { dataPlans, isErrorDataPlan, isSuccessDataPlan, messageDataPlan, isLoadingDataPlan } = useSelector(dataPackage)
  const { balance } = useSelector(walletBalance)
  const [network, setNetwork] = useState('')
  const [dataOptions, setDataOptions] = useState([])
  const [dataPlanId, setDataPlanId] = useState('')
  const [planId, setPlanId] = useState()
  const [amount, setAmount] = useState()
  const [Loading, setLoading] = useState(false)
  const [size, setSize] = useState()
  const [productCode, setProductCode] = useState()
  const [disable, setDisable] = useState(false)
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"
  const gloNetwork = 'glo'
  const mtnNetwork = 'mtn'
  const nineMobileNetwork = '9mobile'
  const airtelNetwork = 'airtel'
  const buySmeDataApi = '/api/data/buy-sme-data'
  const SmeDataSuccessMessage = 'SME Data Purchased Successfully'
  const buyTranzitApi = '/api/data/buy-transit-data'
  const tranzitDataSuccessMessage = 'Data Purchased Successfully'
  const activeProviderApi = '/api/dataplan/get-active-provider'
  const smeProvider = 'sme'
  const tranzitProvider = 'tranzit'

  const [form] = Form.useForm();

  const serviceId = {
    "mtn": 1,
    "glo": 2,
    "airtel": 3,
    "9mobile": 4
  }


  useEffect(() => {
    dispatch(getAllDataPlan())
  }, [])

  useEffect(() => {
    if (user) {
      if (messageDataPlan === errorTokenExpired || messageDataPlan === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (messageDataPlan !== '') {
        toast.error(messageDataPlan)
      }
    }
    dispatch(resetDataPlan())
  }, [messageDataPlan, isSuccessDataPlan])



  const networkOnChange = (value) => {
    setNetwork(value)
  }

  const dataPlanOnChange = (value) => {
    setDataPlanId(value)
  }

  // const mobileNumberFormat = (number) => {
  //   let numberFormat = '234' + number.substring(1)
  //   return numberFormat
  // }

  useEffect(() => {
    form.resetFields(['dataplanId']);
    setAmount()
    if (network === gloNetwork) {
      const gloDataPlans = dataPlans?.filter((gloData) => gloData?.network === gloNetwork && gloData?.provider?.isActive === true)
      setDataOptions(gloDataPlans)
    }
    if (network === mtnNetwork) {
      const mtnDataPlans = dataPlans?.filter((mtnData) => mtnData?.network === mtnNetwork)
      setDataOptions(mtnDataPlans)
    }
    if (network === nineMobileNetwork) {
      const nineMobileDataPlans = dataPlans?.filter((nineMobileData) => nineMobileData?.network === nineMobileNetwork)
      setDataOptions(nineMobileDataPlans)
    }
    if (network === airtelNetwork) {
      const airtelDataPlans = dataPlans?.filter((airtelData) => airtelData?.network === airtelNetwork)
      setDataOptions(airtelDataPlans)
    }
  }, [network])

  useEffect(() => {
    const dataSelect = dataOptions?.find((data) => data?._id === dataPlanId)
    setPlanId(dataSelect?.planId)
    setAmount(dataSelect?.amount)
    setSize(dataSelect?.volume + dataSelect?.unit)
    setProductCode(dataSelect?.productCode)
  }, [dataPlanId])



  // Glo Provider
  // const buyData = async(formData) => {
  //   const headers = {
  //     'Authorization': `Bearer ${user?.accessToken}`,
  //     'Content-Type': 'application/json'
  //   };
  //     try {
  //       setLoading(true)
  //       setDisable(true)
  //       const response = await axios.post(baseApiUrl + '/api/data' + '/buy-data', formData, {headers} )
  //       if(response.data.message !== 'Data Purchased Successfully' ){
  //         if(response.data.walletBalance){
  //           dispatch(updateWalletBalance(response.data.walletBalance))
  //           toast.info(response.data.message)
  //         } else {
  //           toast.error(response.data.message)
  //         }
  //       } 
  //       if (response.data.message === 'Data Purchased Successfully') {
  //         dispatch(updateWalletBalance(response.data.walletBalance))
  //         toast.success(response.data.message)
  //       }
  //     } catch (error) {
  //       if(error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
  //         toast.error('Error!! Please Login again')
  //         dispatch(logout())
  //       } else if (error.response.data){
  //       toast.error(error.response.data.message)
  //       } else{
  //         toast.error(error)
  //       }
  //     } finally {
  //       form.resetFields()
  //       setAmount()
  //       setLoading(false)
  //       setDisable(false)
  //     }
  // }

  // SME Provider
  const BuySMEDataPlan = async (formData) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on Buy Now',
      label: 'Buy Data',
    });
    const headers = {
      'Authorization': `Bearer ${user?.accessToken}`,
      'Content-Type': 'application/json'
    };
    try {
      setLoading(true)
      setDisable(true)
      const response = await axios.post(baseApiUrl + buySmeDataApi, formData, { headers })
      if (response.data.message !== SmeDataSuccessMessage) {
        toast.info(response.data.message)
      }
      if (response.data.message === SmeDataSuccessMessage) {
        dispatch(updateWalletBalance(response.data.walletBalance))
        toast.success(response.data.message)
      }
    } catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (error.response.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    } finally {
      form.resetFields()
      setAmount()
      setLoading(false)
      setDisable(false)
    }
  }

  //Tranzit Provider
  const BuyTranzitDataPlan = async (formData) => {
    const headers = {
      'Authorization': `Bearer ${user?.accessToken}`,
      'Content-Type': 'application/json'
    };
    try {
      setLoading(true)
      setDisable(true)
      const response = await axios.post(baseApiUrl + buyTranzitApi, formData, { headers })
      if (response.data.message !== tranzitDataSuccessMessage) {
        toast.info(response.data.message)
      }
      if (response.data.message === tranzitDataSuccessMessage) {
        dispatch(updateWalletBalance(response.data.walletBalance))
        toast.success(response.data.message)
      }
    } catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (error.response.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    } finally {
      form.resetFields()
      setAmount()
      setLoading(false)
      setDisable(false)
    }
  }

  const getActiveProvider = async () => {
    const headers = {
      'Authorization': `Bearer ${user?.accessToken}`,
      'Content-Type': 'application/json'
    };
    try {
      const response = await axios.get(baseApiUrl + activeProviderApi, { headers })
      if (response.data) {
        return response.data
      }
    } catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (error.response) {
        toast.error(error.response.data)
      } else {
        toast.error(error)
      }
    }
  }

  const onFinish = async (e) => {
    setDisable(true)
    setLoading(true)
    const activeProvider = await getActiveProvider()
    if (!amount) {
      toast.info('No amount!! Please refresh page')
      form.resetFields()
      setAmount()
      setDisable(false)
      setLoading(false)
    } else if (amount > balance) {
      toast.error('Insufficient Funds in Wallet')
      form.resetFields()
      setAmount()
      setDisable(false)
      setLoading(false)
    } else if (e.msisdn.length !== 11) {
      toast.info('Mobile Number is Incomplete')
      form.resetFields()
      setAmount()
      setDisable(false)
      setLoading(false)
    } else if (e.msisdn[0] !== '0') {
      toast.info('Mobile Number Format is incorrect')
      form.resetFields()
      setAmount()
      setDisable(false)
      setLoading(false)
    } else if (e.msisdn[0] === '0' && e.msisdn.length === 11) {
      //  if(e.network === gloNetwork){
      //   const phoneNumber = mobileNumberFormat(e.msisdn)
      //   const FormData = {dataplanId: e.dataplanId, msisdn: phoneNumber, amount, planId}
      //   buyData(FormData)}
      if (activeProvider === smeProvider) {
        if (e.network === mtnNetwork || e.network === airtelNetwork) {
          const FormData = { dataplanId: e.dataplanId, phone: e.msisdn, size, network: e.network, amount }
          BuySMEDataPlan(FormData)
        }
      } else if (activeProvider === tranzitProvider) {
        let FormData;
        FormData = { dataplanId: e.dataplanId, Number: e.msisdn, Plan: productCode, Service: serviceId[`${e.network}`], amount }
        BuyTranzitDataPlan(FormData)
      }
    }
  }
  // const onFinish = async(e) => {
  //   setDisable(true)
  //   setLoading(true)
  //   const activeProvider = await getActiveProvider()
  //   if(e.network === gloNetwork){
  //     if(!amount){
  //       toast.info('No amount!! Please refresh page')
  //      } else if( amount >  balance) {
  //        toast.error('Insufficient Funds in Wallet')
  //      } else if(e.msisdn[0] === '0' && e.msisdn.length === 11){
  //        const phoneNumber = mobileNumberFormat(e.msisdn)
  //        const FormData = {dataplanId: e.dataplanId, msisdn: phoneNumber, amount, planId}
  //        buyData(FormData)
  //      } else if (e.msisdn.length !== 11){
  //        toast.info('Mobile Number is Incomplete')
  //      } else {
  //        toast.info('Mobile Number Format is incorrect')
  //      }
  //    } else if(activeProvider === smeProvider){
  //     if(e.network === mtnNetwork || e.network === airtelNetwork){
  //       if(!amount){
  //         toast.info('No amount!! Please refresh page')
  //        } else if( amount > balance) {
  //          toast.error('Insufficient Funds in Wallet')
  //        } else if(e.msisdn[0] === '0' && e.msisdn.length === 11){
  //          const FormData = {dataplanId: e.dataplanId, phone: e.msisdn, size, network: e.network, amount} 
  //          BuySMEDataPlan(FormData)
  //        } else if (e.msisdn.length !== 11){
  //          toast.info('Mobile Number is Incomplete')
  //        } else {
  //          toast.info('Mobile Number Format is incorrect')
  //        }
  //      }
  //   } else if(activeProvider === tranzitProvider){
  //     if(!amount){
  //       toast.info('No amount!! Please refresh page')
  //      } else if( amount > balance) {
  //        toast.error('Insufficient Funds in Wallet')
  //      } else if(e.msisdn[0] === '0' && e.msisdn.length === 11){
  //        let FormData;
  //        if(e.network === airtelNetwork){
  //         FormData = {dataplanId: e.dataplanId, number: e.msisdn, productCode, network: 3, amount} 
  //        } else {
  //         FormData = {dataplanId: e.dataplanId, number: e.msisdn, productCode, network: e.network.toUpperCase(), amount} 
  //        }
  //        BuyTranzitDataPlan(FormData)
  //      } else if (e.msisdn.length !== 11){
  //        toast.info('Mobile Number is Incomplete')
  //      } else {
  //        toast.info('Mobile Number Format is incorrect')
  //      }
  //   }
  //   setLoading(false)
  //   setDisable(false)
  // }

  // const onConfirm = (e) => {
  //   setConfirmModal(true)
  //   setDataInfo(e)
  // }


  return (
    <>
      <Helmet defer={false}>
        <title>Fliprecharge || Buy Data</title>
      </Helmet>
      {Loading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Buy Data</h3>
              </CardHeader>
              <CardBody>
                <Form form={form} className='w-100 bg-white p-4 rounded-xl' onFinish={onFinish}>
                  <label htmlFor="">Network</label>
                  <Form.Item className="w-100" name="network" rules={[{ required: 'true', message: "Please select a network" }]}>
                    <Select value={network} onChange={networkOnChange}>
                      <Select.Option value={mtnNetwork}>MTN</Select.Option>
                      <Select.Option value={nineMobileNetwork}>9Mobile</Select.Option>
                      <Select.Option value={gloNetwork}>Glo</Select.Option>
                      <Select.Option value={airtelNetwork}>Airtel</Select.Option>
                    </Select>
                  </Form.Item>
                  <label htmlFor="">Data Plans</label>
                  <Form.Item className="w-100" name="dataplanId" rules={[{ required: 'true', message: "Please select a data plan" }]}>
                    <Select value={dataPlanId} onChange={dataPlanOnChange}>
                      {dataOptions?.map((dataOption, key) =>
                        <Select.Option value={dataOption?._id} key={key}>{dataOption?.network.toUpperCase()} - {dataOption?.volume + dataOption?.unit} for {dataOption?.validity + 'days'}</Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                  <label htmlFor="">Mobile Number</label>
                  <Form.Item className="w-100" name="msisdn" rules={[{ required: 'true', message: "Please input your Mobile Number" }]}>
                    <Input maxLength={11}
                      suffix={
                        <Tooltip title="Eg: 0812345678">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <label htmlFor="">Amount</label>
                  <Form.Item className="w-100">
                    <Input value={amount} disabled type='number' />
                  </Form.Item>
                  <Form.Item>
                    <Button className="bg-gradient-info text-white d-flex justify-content-center align-items-center my-3 px-4 py-3 rounded-lg" htmlType="submit" disabled={disable}>Buy now</Button>
                  </Form.Item>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Buy_Data;
