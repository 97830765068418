import React, { useEffect, useState } from 'react'
import MTNLOGO from '../Logos/New-mtn-logo.jpg';
import GLOLOGO from '../Logos/Glo-Logo.png'
import NINEMOBILELOGO from '../Logos/9mobile-Logo.svg'
import AIRTELLOGO from '../Logos/Airtel-logo.png'
import { AIRTELDATA, GLODATA, MTNDATA, NINEMOBILEDATA } from '../Data';
import {HiOutlineShoppingCart} from 'react-icons/hi'
import axios from 'axios';
import { baseApiUrl } from '../Utils/constants';
import { useSelector } from 'react-redux';
import { getUser } from '../features/Auth/AuthSlice';
import { useNavigate } from 'react-router-dom';

const DataPlans = () => {
    const [dataPlans, setDataPlans] = useState([] || '')
    const [mtnDataPlans, setMtnDataPlans] = useState([])
    const [gloDataPlans, setGloDataPlans] = useState([])
    const [airtelDataPlans, setAirtelDataPlans] = useState([])
    const [nineMobileData, setNineMobileData] = useState([])
    const {user} = useSelector(getUser)
    const navigate = useNavigate()

    useEffect(()=> {
        const fetchDataPlans = async()=> {
           try {
            const response = await axios.get(`${baseApiUrl}/api/dataplan/get-dataplan-homepage`)
            const resData = response?.data?.sort((a, b) => b.unit.localeCompare(a.unit))
            setDataPlans(resData)
           } catch (error) {
            if(error.response){
                setDataPlans('Error')
              } else {
                setDataPlans('Error')
              }
           }
        }
        fetchDataPlans()
    }, [])

    useEffect(()=> {
        if(dataPlans === 'Error'){

        } else {
           const mtn = dataPlans?.filter((data)=> data?.network === 'mtn')
           const glo = dataPlans?.filter((data)=> data?.network === 'glo' && data?.provider?.isActive === true)
           const airtel = dataPlans?.filter((data)=> data?.network === 'airtel')
           const nineMobile = dataPlans?.filter((data)=> data?.network === '9mobile')
           if(mtn){
            setMtnDataPlans(mtn)
           }
           if(glo){
            setGloDataPlans(glo)
           }
           if(airtel){
               setAirtelDataPlans(airtel)
           }
           if(nineMobile){
               setNineMobileData(nineMobile)
           }
        }
    }, [dataPlans])

    const durationFormat = (duration) => {
        if(duration > 1){
            return duration + 'days'
        } else {
            return duration + 'day'
        }
    }

    const onBuyNow = () => {
        if(user){
            navigate('/user')
        }else {
            navigate('/auth/login')
        }
    }

    
  return (
    <div id='DataPlans' className='w-100 mx-auto'>
    <div className='w-100 px-4 mx-auto'>
          {/* Title */}
     <h1 className='text-center mt-5' style={{color: "#1171ef", fontSize: "50px"}}>Affordable Data Plans And Prices</h1>
     {/* Data Plans Div */}
     <div className='dataplandiv d-flex w-100 mx-auto justify-content-center p-4 my-2 flex-xl-nowrap flex-wrap'>
         {/* MTN DIV */}
         <div className='col-12 col-md-8 col-xl-3 my-3 mx-2 py-5' style={{backgroundColor: "rgb(229 231 235)", height: "fit-content"}}>
             {/* MTN LOGO */}
             <div className='text-center'><img className='' src={MTNLOGO} style={{width: "80px"}} alt="" />
             <h4>MTN DATA</h4>
             </div>
             {/* MTN DATA PLANS */}
             <div className='text-center my-5'>
                 {mtnDataPlans?.map((data, id) => 
                 <div className='d-flex my-3 align-items-center text-center w-100 justify-content-between border-2 border-bottom border-dark' style={{minWidth: "275px"}}>
                     <p className='col-4'>{data?.volume + data?.unit}</p>
                     <p className='col-4'>₦{data?.amount}</p>
                     <p className='col-4'>{durationFormat(data?.validity)}</p>
                 </div>
                 )}
             </div>
             <div className='d-flex justify-content-center align-items-center  mx-auto w-50 py-2' style={{cursor: "pointer", background:"#1171ef", color: "white"}}><HiOutlineShoppingCart size={25} /><p className='m-0 mx-1' style={{fontSize: '20px'}} onClick={onBuyNow}>Buy now</p></div>
         </div>
         {/* GLO DIV */}
         <div className='col-12 col-md-8 col-xl-3 my-3 mx-2 py-5' style={{backgroundColor: "rgb(229 231 235)", height: "fit-content"}}>
         {/* GLO LOGO */}
        <div className='text-center'>
        <img src={GLOLOGO} style={{width:"80px"}} alt="" />
         <h4>GLO DATA</h4>
        </div>
         {/* GLO DATA PLANS */}
         <div className='text-center my-5'>
             {gloDataPlans?.map((data, id)=> 
             <div className='d-flex my-3 align-items-center text-center w-100 justify-content-between border-2 border-bottom border-dark' style={{minWidth: "275px"}}>
                <p className='col-4'>{data?.volume + data?.unit}</p>
                <p className='col-4'>₦{data?.amount}</p>
                <p className='col-4'>{durationFormat(data?.validity)}</p>
             </div>
             )}
         </div>
         <div className='d-flex justify-content-center align-items-center mx-auto w-50 py-2' style={{cursor: "pointer", background:"#1171ef", color: "white"}}><HiOutlineShoppingCart size={25} /><p className='m-0 mx-1' style={{fontSize: '20px'}} onClick={onBuyNow}>Buy now</p></div>
         </div>
         {/* AIRTEL DIV */}
         <div className='col-12 col-md-8 col-xl-3 my-3 mx-2 py-5' style={{backgroundColor: "rgb(229 231 235)", height: "fit-content"}}>
             {/* AIRTEL LOGO */}
             <div className='text-center'>
             <img src={AIRTELLOGO} style={{width: "80px"}} alt="" />
             <h4>AIRTEL DATA</h4>
             </div>
             {/* AIRTEL DATA PLANS */}
             <div className='text-center my-5'>
                 {airtelDataPlans?.map((data, id)=>
                 <div className='d-flex my-3 align-items-center text-center justify-content-between border-2 border-bottom border-dark' style={{minWidth: "275px"}}>
                    <p className='col-4'>{data?.volume + data?.unit}</p>
                    <p className='col-4'>₦{data?.amount}</p>
                    <p className='col-4'>{durationFormat(data?.validity)}</p>
                 </div>
                 )}
             </div>
             <div className='d-flex justify-content-center align-items-center  mx-auto w-50 py-2' style={{cursor: "pointer", background:"#1171ef", color: "white"}}><HiOutlineShoppingCart size={25} /><p className='m-0 mx-1' style={{fontSize: '20px'}} onClick={onBuyNow}>Buy now</p></div>
         </div>
         {/* 9mobile DIV */}
         <div className='col-12 col-md-8 col-xl-3 my-3 mx-2 py-5' style={{backgroundColor: "rgb(229 231 235)", height: "fit-content"}}>
             {/* 9mobile LOGO */}
             <div className='text-center'>
             <img src={NINEMOBILELOGO} style={{width: "80px"}} alt="" />
             <h4>9MOBILE DATA</h4>
             </div>
             {/*9MOBILE  DATA PLANS */}
             <div className='text-center my-5'>
                 {nineMobileData.map((data, id)=> 
                 <div id={id} className='d-flex my-3 align-items-center justify-content-between border-2 border-bottom border-dark' style={{minWidth: "275px"}}>
                    <p className='col-4'>{data?.volume + data?.unit}</p>
                    <p className='col-4'>₦{data?.amount}</p>
                    <p className='col-4'>{durationFormat(data?.validity)}</p>
                 </div>
                 )}
             </div>
             <div className='d-flex justify-content-center align-items-center mx-auto w-50 py-2' style={{cursor: "pointer", background:"#1171ef", color: "white"}}><HiOutlineShoppingCart size={25} /><p className='m-0 mx-1' style={{fontSize: '20px'}} onClick={onBuyNow}>Buy now</p></div>
         </div>
     </div>
    </div>
 </div>
  )
}

export default DataPlans