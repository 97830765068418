import React from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import Spinner from "../../../Component/Spinner";
import { Helmet } from 'react-helmet-async'
import axios from 'axios';
import { baseApiUrl } from '../../../Utils/constants';

const Confirm_Email = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
  
    const [formData, setFormData] = useState({
      email: ""
  })
  
  const {email} = formData

    const onChange = (e) => {
        setFormData((prevState)=> ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
      }

    const verifyEmail = async(userData) => {
      try {
        setIsLoading(true)
        const response =  await axios.post(baseApiUrl + "/user/forgot-password", userData)
        toast.success(response.data)
      } catch (error) {
        toast.error(error.response.data)
      } finally {
        setIsLoading(false)
      }
    }
    const onSubmit = (e) => {
        e.preventDefault()
        if(!email){
            toast.error('Input your email address')
        } else {
        const userData = {
            email: email.toLowerCase()
        }
        verifyEmail(userData)
        }
    }
  return (
    <>
         <Helmet defer={false}>
        <title>Telcom || Reset Password</title>
    </Helmet>
    {isLoading && <Spinner />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small style={{fontSize: "20px"}}>Confirm Email Address</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    required
                    autoComplete="new-email"
                    name="email"
                    value={email}
                    onChange={onChange}
                  />
                </InputGroup>
              </FormGroup>
              {/* <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    required
                    autoComplete="new-password"
                    name="password"
                    value={password}
                    onChange={onChange}
                  />
                </InputGroup>
              </FormGroup> */}
              <div className="text-center">
                <Button className="my-4" color="primary" onClick={onSubmit} type="button">
                    Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  )
}

export default Confirm_Email