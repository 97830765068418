import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import PaymentService from './PaymentService'

const initialState = {
    allPayment: [],
    isLoadingAllPayment: false,
    isErrorAllPayment: false,
    isSuccessAllPayment: false,
    isMessageAllPayment: ''
}


//get all payments
export const getAllPayment = createAsyncThunk('userPayments/get', async(_, thunkAPI)=> {
    try {
        const accesstoken = thunkAPI.getState().auth.user.accessToken
        return await PaymentService.findAllPayment(accesstoken)
    } catch (error) {
        const message = error.response.data
        return thunkAPI.rejectWithValue(message)
    }
})


const AllPaymentSlice = createSlice({
  name: 'allPayment',
  initialState,
  reducers: {
    resetAllPayment: (state)=> {
        state.isLoadingAllPayment = false
        state.isErrorAllPayment = false
        state.isSuccessAllPayment = false
        state.isMessageAllPayment = ''
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAllPayment.pending, (state)=> {
        state.isLoadingAllPayment = true
    })
    .addCase(getAllPayment.fulfilled, (state, action)=> {
        state.isLoadingAllPayment = false
        state.isSuccessAllPayment = true
        state.allPayment = action.payload 
    })
    .addCase(getAllPayment.rejected, (state, action)=> {
        state.isLoadingAllPayment = false
        state.isErrorAllPayment = true
        state.isMessageAllPayment = action.payload
    })
  }
});


export const allPayments = state => state.allPayment

export const {resetAllPayment} = AllPaymentSlice.actions

export default AllPaymentSlice.reducer