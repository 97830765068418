import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
} from "reactstrap";
// core components
import { Button, Form, Input } from 'antd'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../../../features/Auth/AuthSlice.jsx";
import { baseApiUrl } from "../../../Utils/constants.js";
import axios from 'axios';
import { Modal } from "antd";

import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import { updateFundWalletOption, updateWalletBalance, walletBalance } from "../../../features/Wallet/WalletSlice.jsx";
import Spinner from "../../../Component/Spinner.jsx";
import PAYSTACKLOGO from '../../../Logos/Paystack-Logo.png'
import MONNIFYLOGO from '../../../Logos/monnify-icon.webp'
import { FaArrowDown } from 'react-icons/fa'

const Fund_Wallet = () => {
  const { user } = useSelector(getUser)
  const [accountDetails, setAccountDetails] = useState(user?.accountDetails);
  const [amount, setAmount] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { balance, fundWalletOption } = useSelector(walletBalance)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [transferModal, setTransferModal] = useState(false)
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"
  const completeMonnifyApi = '/api/payment/monify/complete/'
  const fundWalletParams = '/user/fund-wallet'
  const selectFundingOption = 'Select Option'
  const cardFundingOption = 'Card'
  const transferFundingOption = 'Transfer'
  const initializeMonnifyPaymentApi = "/api/payment/monify/initialize"
  const createReservedAccountApi = "/user/create-reserved-account"

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const reference = urlParams.get('trxref');
    const paymentReference = urlParams.get('paymentReference');
    // monnify
    const completeMonifyTransaction = async () => {
      try {
        const headers = {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'

        };
        setLoading(true)
        const response = await axios.get(baseApiUrl + `${completeMonnifyApi}${paymentReference}`, { headers });
        if (response.status === 200) {
          // Dispatch the action to update the wallet balance in Redux store
          dispatch(updateWalletBalance(response.data.walletbalance));
          // localStorage.setItem("userTelecomBalance", JSON.stringify(response.data.walletbalance))
          toast.success("Wallet Funded Sucessfully")
          navigate(fundWalletParams)
        }
        else {
          toast.error(" Wallet Not Funded Sucessfully due to " + response.data.message)
        }
      }
      catch (error) {
      } finally {
        setLoading(false)
      }
    };

    // if (reference) {
    //   verifyTransaction();
    // }
    if (paymentReference) {
      completeMonifyTransaction();
      dispatch(updateFundWalletOption(cardFundingOption))
    }
    if (!paymentReference) {
      dispatch(updateFundWalletOption(selectFundingOption))
    }
  }, []);

  useEffect(() => {
    if (fundWalletOption === selectFundingOption) {
      setModal(true)
    } else if (fundWalletOption === transferFundingOption) {
      setModal(false)
      setTransferModal(true)
    } else {
      setModal(false)
      setTransferModal(false)
    }
  }, [fundWalletOption])



  const handleFundWallet = async (e) => {
    e.preventDefault();
    const headers = {
      'Authorization': `Bearer ${user?.accessToken}`,
      'Content-Type': 'application/json'
    };
    const data = {
      amount: amount
    }

    try {
      setLoading(true)
      // Monnify
      const monnifyresponse = await axios.post(baseApiUrl + initializeMonnifyPaymentApi, data, { headers });
      if (monnifyresponse.data.success) {
        window.location.href = monnifyresponse.data.checkoutUrl;
      }
    }
    catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else {
        toast.error(error.response.data.error.message || error.response.data || 'Error Occured while funding wallet')
      }
    } finally {
      setLoading(false)
    }
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    setLoading(true)
    const headers = {
      'Authorization': `Bearer ${user?.accessToken}`,
      'Content-Type': 'application/json'
    };
    const data = {
      user: user
    }
    try {
      const response = await axios.post(baseApiUrl + createReservedAccountApi, data, { headers });

      if (response.data.status === 200 && response.data.success === true) {
        toast.success(response.data.message)

      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error("Reserved Account Not Created Successfully")
    }
    finally {
      setLoading(false)
    }
  };

  let accountDetailsContent;
  if (Array.isArray(user?.accountDetails) && user?.accountDetails.length > 0) {
    // When accountDetails is an array and contains data
    accountDetailsContent = user.accountDetails.map((data) => (
      <div className="bg-success m-4 d-flex flex-column p-4 rounded-lg" key={data.accountNumber}>
        <h3>Bank Name: {data.bankName}</h3>
        <h3>Account Name: {data.accountName}</h3>
        <h3>Account Number: {data.accountNumber}</h3>
      </div>
    ));
  } else if (user?.accountDetails === "No account") {
    // When accountDetails is the string "No account"
    accountDetailsContent = (
      <div className="bg-white m-4 d-flex flex-column p-4 rounded-lg">
        <h4>You don't have a reserved account. Please kindly pay via One Time Payment or transfer to the Fliprecharge acount below.</h4>
        <h1 className="text-center pt-5">Account Details : </h1>
        <div className="d-flex flex-wrap justify-content-around">
          <h3>Bank Name: Moniepoint MFB</h3>
          <h3>Account Name: Flip Recharge Biz</h3>
          <h3>Account Number: 5346902336</h3>
        </div>
        <h4 className="text-center pt-5">Kindly send your proof of payment to this number on WhatsApp: 08032002462</h4>
      </div>
      /* <button className="btn btn-primary mt-2" onClick={handleCreateAccount}>Generate Account</button> */

    );
  }
  else if (user?.accountDetails.length === 0) {
    // When accountDetails is the string "No account"
    accountDetailsContent = (
      <div className="bg-white m-4 d-flex flex-column p-4 rounded-lg">
        <h4>You don't have a reserved account. Please click the button below to generate one.</h4>
        <button className="btn btn-primary mt-2" onClick={handleCreateAccount}>Generate Account</button>
      </div>
    );
  }
  else {
    // Fallback or default content if accountDetails is neither an array nor the specific string
    accountDetailsContent = (
      <div className="m-4 d-flex flex-column p-4 rounded-lg">
        <p>Account details are unavailable at the moment.</p>
      </div>
    );
  }


  return (
    <>
      <Helmet defer={false}>
        <title>Fliprecharge || Fund Wallet</title>
      </Helmet>
      {loading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>

      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        {/* Table */}
        <Modal open={modal} onCancel={() => setModal(false)} title='Fund Wallet' className="" style={{ height: "60vh", top: "30vh" }} footer={null}>
          {/* <div className="bg-gradient-info p-2 rounded-3 d-flex align-items-center my-4" style={{ cursor: "pointer" }} onClick={() => dispatch(updateFundWalletOption(cardFundingOption))}>
            <img className='bg-white p-1 rounded-sm' src={MONNIFYLOGO} style={{ width: "40px", marginRight: "40px" }} alt="" /> <h2 className="mx--4">Pay Using One Time Payment</h2>
          </div> */}
          <div className="bg-gradient-info p-2 rounded-3 d-flex align-items-center my-4" style={{ cursor: "pointer" }} onClick={() => dispatch(updateFundWalletOption(transferFundingOption))}>
            <img className='rounded-sm' src={MONNIFYLOGO} style={{ width: "40px", marginRight: "40px" }} alt="" /> <h2 className="mx--4">Bank Transfer</h2>
          </div>
        </Modal>
        <Modal open={transferModal} onCancel={() => setTransferModal(false)} title='Fund Wallet' className="" style={{ height: "60vh", top: "30vh" }} footer={null}>
          <div className="bg-gradient-info p-2 rounded-3 d-flex align-items-center my-4" style={{ cursor: "pointer" }} onClick={() => dispatch(updateFundWalletOption('Reserved_Account'))}>
            <img className='bg-white p-1 rounded-sm' src={MONNIFYLOGO} style={{ width: "40px", marginRight: "40px" }} alt="" /> <h2 className="mx--4">Transfer to Reserved Account</h2>
          </div>
          <div className="bg-gradient-info p-2 rounded-3 d-flex align-items-center my-4" style={{ cursor: "pointer" }} onClick={() => dispatch(updateFundWalletOption('Flip_Recharge_Account'))}>
            <img className='rounded-sm' src={MONNIFYLOGO} style={{ width: "40px", marginRight: "40px" }} alt="" /> <h2 className="mx--4">Transfer to Flip Recharge Account</h2>
          </div>
        </Modal>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent d-flex flex-column flex-md-wrap flex-lg-row justify-content-lg-between justify-content-center align-items-center">
                <h3 className="mb-0 d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => setModal(true)}>Fund Wallet<h3 className="me-1 bg-gradient-info px-2 mx-2 py-1 shadow-xl rounded-lg">{fundWalletOption}<FaArrowDown className="mx-2" /></h3></h3>
                <h3 className="mb-0 bg-gradient-info px-4 py-2 rounded-lg text-white"> Wallet Balance: ₦{balance?.toFixed(2)}</h3>
              </CardHeader>
              {fundWalletOption === 'Reserved_Account' && <div className="p-3 shadow-xl" style={{ fontFamily: 'Playfair Display, serif', background: '#b8f2dd' }}>
                <h3 className="text-center">Transfer to your reserved account to automatically fund your wallet</h3>
                <h4 className="text-center pt-2 ">Please note that there is a 1.5% service charge applicable to your payment. This charge is exclusive of any Value Added Tax (VAT).
                  For example, if you are making a payment of ₦10,000, the 1.5% service charge would be calculated as follows:</h4>
                <div className="d-flex flex-wrap justify-content-around text-center my-4" style={{ fontFamily: 'Playfair Display, serif' }}>
                  <h4>Payment Amount: ₦10,000 </h4>
                  <h4>Service Charge (1.5% of Payment Amount): ₦150 </h4>
                  <h4>VAT: ₦11.25 </h4>
                  <h4>Settled Amount: ₦9,838.75</h4>
                </div>
                <h1 className="text-center pt-3">Account Details : </h1>
                <div className="d-flex flex-wrap justify-content-center align-items-center pb-3">
                  {accountDetailsContent}
                </div>
              </div>
              }
              {fundWalletOption === 'Flip_Recharge_Account' && <div className=" p-3 shadow-xl" style={{ fontFamily: 'Playfair Display, serif', background: '#b8f2dd' }}>
                <h3 className="text-center">Transfer to our account to automatically fund your wallet</h3>
                <h4 className="text-center pt-2">Please note that there is a no service charge applicable to your payment</h4>
                {/* <div className="d-flex flex-wrap justify-content-around mt-4 text-center" style={{fontFamily: 'Playfair Display, serif'}}>
                    <h4>Payment Amount: ₦10,000 </h4>
                    <h4>Service Charge: ₦50 </h4>
                    <h4>Settled Amount: ₦9,950 </h4>
                  </div> */}
                <h1 className="text-center pt-5">Account Details : </h1>
                <div className="d-flex flex-wrap justify-content-around">
                  <h3>Bank Name: Moniepoint MFB</h3>
                  <h3>Account Name: Flip Recharge Biz</h3>
                  <h3>Account Number: 5346902336</h3>
                </div>
                <h4 className="text-center pt-5">Kindly send your proof of payment to this number on WhatsApp: 08032002462</h4>
              </div>
              }
              <CardBody>
                {fundWalletOption === cardFundingOption && <div>
                  <div className=" p-3 shadow-xl" style={{ fontFamily: 'Playfair Display, serif', background: '#b8f2dd' }}>
                    <h4 className="text-center pt-2">Please note that there is a 1.5% service charge applicable to your payment. This charge is exclusive of any Value Added Tax (VAT).
                      For example, if you are making a payment of ₦10,000, the 1.5% service charge would be calculated as follows:</h4>
                    <div className="d-flex flex-wrap justify-content-around pt-3 text-center" style={{ fontFamily: 'Playfair Display, serif' }}>
                      <h4>Payment Amount: ₦10,000 </h4>
                      <h4>Service Charge (1.5% of Payment Amount): ₦150 </h4>
                      <h4>VAT: ₦11.25 </h4>
                      <h4>Settled Amount: ₦9,838.75</h4>
                    </div>
                  </div>
                  <Form className='w-100 bg-white p-4 rounded-xl ' >
                    <label htmlFor="">Name</label>
                    <Form.Item>
                      <Input disabled value={user?.username} />
                    </Form.Item>
                    <label htmlFor="">Amount</label>
                    <Form.Item>
                      <Input value={amount} onChange={(event) => { setAmount(event.target.value) }} />
                    </Form.Item>
                    <Button type="button" onClick={handleFundWallet} className="bg-gradient-info text-white">Fund Wallet</Button>
                  </Form>
                </div>
                }
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Fund_Wallet