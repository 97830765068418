import React from 'react'
import { Link } from 'react-router-dom'
import {FiLogIn} from 'react-icons/fi'
import 'animate.css'
import { Helmet } from 'react-helmet-async'
import Image from '../Images/Landing.jpg'

const Banner = () => {
  return (
    <div id='Home'className='' >
      <Helmet>
        <title>Fliprecharge || Buy Data, Airtime to Cash, Bill Payments</title>
      </Helmet>
        <section className='container-fluid w-100 p-0 position-relative mt-6'>
            <img className='container-fluid w-100 p-0 m-0' style={{height: '90vh'}} src={Image} alt="" />
            <div className='position-absolute top-0 start-0 w-100 h-100' style={{background: "rgb(0, 0, 0)", opacity: "0.6" }}>
            </div>
            <div className='position-absolute top-0 start-0 w-100 justify-content-center h-100 align-items-center d-flex flex-column'>
            <h1 className='animate__animated animate__backInLeft animate__delay-1s animate__infinite animate__slow fs-1' style={{color: "white"}}>Welcome to<span className='mx-2' style={{color: "#1171ef"}}> FLIPRECHARGE!</span></h1>
            <p className='my-3 text-center' style={{color: "white", fontSize: "20px"}}>At FLIPRECHARGE, we are dedicated to providing you with the most affordable and cheapest data, airtime.</p>
            <div className='px-3 py-2' style={{background: "#1171ef", fontSize: "20px", fontWeight: "lighter", color: "white", cursor:"pointer"}}><Link to='/auth/register' style={{color: "white"}}><FiLogIn />   REGISTER</Link></div>
            </div>
        </section>
    </div>
  )
}

export default Banner