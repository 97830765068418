import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { baseApiUrl } from '../../../Utils/constants'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Tag } from 'primereact/tag'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Card, CardHeader, Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getAllAirtimeTransaction, airtimeTransactionNetwork, resetAirtimeTransactions, airtimeTransactions, airtimeTransactionByDates } from '../../../features/AirtimeTransaction/AirtimeTransactionSlice'
import { getUser, logout } from '../../../features/Auth/AuthSlice'
import Spinner from '../../../Component/Spinner'
import axios from 'axios'
import { DatePicker } from 'antd'

const Airtime_Transactions = () => {
  const { RangePicker } = DatePicker
  const dispatch = useDispatch()
  const [dates, setDates] = useState([])
  const [tableValue, setTableValue] = useState([])
  const [loading, setLoading] = useState(false)
  const { user } = useSelector(getUser)
  const nairaSign = '₦'
  const transaction_complete = 'completed'
  const airtimeTnxByDateApi = "/api/airtime/airtime-transactions-by-dates"
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"
  const gloProductCode = 'GLOVTU'
  const mtnProductCode = 'MTNVTU'
  const airtelProductCode = 'AIRTELVTU'
  const nineMobilePCode = '9MOBILEVTU'
  const mtnAirtimeNetwork = '/user-mtn-airtime'
  const gloAirtimeNetwork = '/user-glo-airtime'
  const airtelAirtimeNetwork = '/user-airtel-airtime'
  const nineMobileAirtimeNetwork = '/user-9mobile-airtime'

  const { airtimeTransaction, airtimeTransactionNetwork, isSuccessAirtimeTranxn, isErrorAirtimeTranxn, messageAirtimeTranxn, isLoadingAirtimeTranxn } = useSelector(airtimeTransactions)

  const amountFormat = (airtimeTransaction) => {
    return nairaSign + airtimeTransaction?.amount
  }

  const airtimeFormat = (airtimeTransaction) => {
    let airtimeOutput = nairaSign + airtimeTransaction?.airtime
    if (airtimeTransaction?.airtime === undefined) {
      airtimeOutput = nairaSign + 0
    }
    return airtimeOutput
  }

  const dateFormat = (date) => {
    const dateFromMongoDB = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour clock format
    };

    const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

    return formatted
  }

  const dateFormatTemplate = (airtimeTransaction) => {
    return dateFormat(airtimeTransaction?.createdAt)
  }

  const statusBodyTemplate = (airtimeTransaction) => {
    return <Tag value={airtimeTransaction.status} severity={getSeverity(airtimeTransaction)}></Tag>;
  };

  const getSeverity = (airtimeTransaction) => {
    switch (airtimeTransaction.status) {
      case 'completed':
        return 'success';

      case 'pending':
        return 'warning';

      case 'processing':
        return 'warning';

      case 'failed':
        return 'danger';

      default:
        return null;
    }
  };

  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const totalAmount = tableValue?.filter((transaction) => transaction.status === transaction_complete)?.reduce((sum, transaction) => sum + transaction?.amount, 0)
  const totalAirtime = tableValue?.filter((transaction) => transaction.status === transaction_complete)?.reduce((sum, transaction) => transaction?.airtime !== undefined ? sum + transaction?.airtime : sum, 0);


  //search date button
  const searchDates = async () => {
    if (dates === null) {
      toast.error('Please Select dates')
    } else {
      const formData = { startDate: dates[0], endDate: dates[1] }
      try {
        const headers = {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'

        };
        setLoading(true)
        const response = await axios.post(baseApiUrl + airtimeTnxByDateApi, formData, { headers })
        if (response.data) {
          dispatch(airtimeTransactionByDates(response.data))
        }
      } catch (error) {
        if (error.response.data !== errorTokenExpired) {
          toast.error(error.response.data)
        } else {
          dispatch(logout())
          toast.error('Error!! Please Login')
        }
      } finally {
        setLoading(false)
      }
    }
  }

  //change in airtime transaction network
  useEffect(() => {
    dispatch(getAllAirtimeTransaction(user?._id))
    if (airtimeTransactionNetwork === gloAirtimeNetwork) {
      const airtimeTranx = airtimeTransaction?.filter(data => data.productCode === gloProductCode)
      setTableValue(airtimeTranx)
    }
    if (airtimeTransactionNetwork === mtnAirtimeNetwork) {
      const airtimeTranx = airtimeTransaction?.filter(data => data.productCode === mtnProductCode)
      setTableValue(airtimeTranx)
    }
    if (airtimeTransactionNetwork === airtelAirtimeNetwork) {
      const airtimeTranx = airtimeTransaction?.filter(data => data.productCode === airtelProductCode)
      setTableValue(airtimeTranx)
    }
    if (airtimeTransactionNetwork === nineMobileAirtimeNetwork) {
      const airtimeTranx = airtimeTransaction?.filter(data => data.productCode === nineMobilePCode)
      setTableValue(airtimeTranx)
    }
    dispatch(resetAirtimeTransactions())
  }, [airtimeTransactionNetwork])

  useEffect(() => {
    if (airtimeTransactionNetwork === gloAirtimeNetwork) {
      const airtimeTranx = airtimeTransaction?.filter(data => data.productCode === gloProductCode)
      setTableValue(airtimeTranx)
    }
    if (airtimeTransactionNetwork === mtnAirtimeNetwork) {
      const airtimeTranx = airtimeTransaction?.filter(data => data.productCode === mtnProductCode)
      setTableValue(airtimeTranx)
    }
    if (airtimeTransactionNetwork === airtelAirtimeNetwork) {
      const airtimeTranx = airtimeTransaction?.filter(data => data.productCode === airtelProductCode)
      setTableValue(airtimeTranx)
    }
    if (airtimeTransactionNetwork === nineMobileAirtimeNetwork) {
      const airtimeTranx = airtimeTransaction?.filter(data => data.productCode === nineMobilePCode)
      setTableValue(airtimeTranx)
    }
    dispatch(resetAirtimeTransactions())
  }, [airtimeTransaction])

  useEffect(() => {
    if (messageAirtimeTranxn === errorTokenExpired || messageAirtimeTranxn === errorTokenInvalid) {
      toast.error('Error!! Please Login again')
      dispatch(logout())
    } else if (messageAirtimeTranxn !== '') {
      toast.error(messageAirtimeTranxn)
    }

    if (isSuccessAirtimeTranxn || isErrorAirtimeTranxn) {
      dispatch(resetAirtimeTransactions())
    }
  }, [isSuccessAirtimeTranxn, isErrorAirtimeTranxn])

  return (
    <div>
      <Helmet defer={false}>
        <title>Fliprecharge || Airtime Transactions</title>
      </Helmet>
      {loading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        <Card className="shadow px-3 pb-4">
          <CardHeader className="bg-transparent">
            <h3 className="mb-0">Airtime Transactions</h3>
          </CardHeader>
          <div className='d-md-flex justify-content-between mt-5'>
            <RangePicker
              className='w-75 w-md-100 my-2 me-5'
              onChange={(values) =>
                setDates(values?.map(item =>
                  item.format("YYYY-MM-DD")
                ))
              }
            />
            <button onClick={searchDates} disabled={!dates} className='btn btn-primary w-25 my-2' style={{ minWidth: "100px" }} type='submit'>Search</button>
          </div>
          <div className='d-md-flex mt-2 text-center'>
            <div className='alert alert-primary fs-5'>Total Airtime: ₦{totalAirtime.toFixed(2)}</div>
            <div className='alert alert-primary mx-md-4 fs-5'>Total Amount: ₦{totalAmount.toFixed(2)}</div>
          </div>
          {isLoadingAirtimeTranxn ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search' className='searchdrop p-2 border w-50 my-2' onInput={(e) => setFilter({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS }, })} />
            <DataTable value={tableValue} filters={filter} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
              <Column field='createdAt' header='Date' body={dateFormatTemplate} style={{ width: '30%' }}></Column>
              <Column field='airtime' header='Airtime' body={airtimeFormat}></Column>
              <Column field='amount' header='Amount' body={amountFormat}></Column>
              <Column field='msisdn' header='Mobile Number' style={{ width: '30%' }}></Column>
              <Column field='status' header='Status' body={statusBodyTemplate}></Column>
            </DataTable></div>}
        </Card>
      </Container>
    </div>
  )
}

export default Airtime_Transactions