import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import PaymentService from './PaymentService'

const initialState = {
    userPayment: [],
    isLoadingUserPayment: false,
    isErrorUserPayment: false,
    isSuccessUserPayment: false,
    isMessageUserPayment: ''
}

//get all user payments
export const getUserPayments = createAsyncThunk('userPayments/get', async(_, thunkAPI)=> {
    try {
        const accesstoken = thunkAPI.getState().auth.user.accessToken
        return await PaymentService.findUserPayments(accesstoken)
    } catch (error) {
        const message = error.response.data
        return thunkAPI.rejectWithValue(message)
    }
})

const userPaymentSlice = createSlice({
  name: 'userPayment',
  initialState,
  reducers: {
    resetUserPayment: (state) => {
        state.isLoadingUserPayment= false
        state.isErrorUserPayment= false
        state.isSuccessUserPayment = false
        state.isMessageUserPayment = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getUserPayments.pending, (state)=> {
        state.isLoadingUserPayment = true
    })
    .addCase(getUserPayments.fulfilled, (state, action)=> {
        state.isLoadingUserPayment = false
        state.isSuccessUserPayment = true
        state.userPayment = action.payload 
    })
    .addCase(getUserPayments.rejected, (state, action)=> {
        state.isLoadingUserPayment = false
        state.isErrorUserPayment = true
        state.isMessageUserPayment = action.payload
    })
  }
});

export const userPayments = state => state.userPayment

export const {resetUserPayment} = userPaymentSlice.actions

export default userPaymentSlice.reducer