/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { getUser, logout } from "../../../features/Auth/AuthSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faUser, faPeopleArrows, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateFundWalletOption, updateWalletBalance, walletBalance } from "../../../features/Wallet/WalletSlice";
import { toast } from "react-toastify";
import { allUsers, getAllUsers, resetAllUsers } from "../../../features/Users/UsersSlice";
import { dataPackage, getAllDataPlan, resetDataPlan } from "../../../features/DataPlan/DataPlanSlice";
import axios from "axios";
import { baseApiUrl } from "../../../Utils/constants";
import { ProgressSpinner } from 'primereact/progressspinner'


const Header = () => {
  const { user } = useSelector(getUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFunding, setIsFunding] = useState(false)
  const [dataTransxn, setdataTransxn] = useState([])
  const [airtimeTransxn, setAirtimeTransxn] = useState([])
  const [walletTransactions, setAllWalletTransactions] = useState([])

  const WalletBalance = useSelector(walletBalance)
  const { dataPlans, isErrorDataPlan, isLoadingDataPlan, isSuccessDataPlan, messageDataPlan } = useSelector(dataPackage)
  const { AllUsers, isSuccessAllUsers, isErrorAllUsers, isMessageAllUsers } = useSelector(allUsers)


  useEffect(() => {
    // if (user?.isAdmin) {
    //   if (isMessageAllUsers === 'token expired' || isMessageAllUsers === 'Token is invalid') {
    //     toast.error('Error!! Please Login again')
    //     dispatch(logout())
    //   } else if (isMessageAllUsers !== '') {
    //     toast.error(isMessageAllUsers)
    //   }

    // } else {
    //   if (messageDataPlan === 'token expired' || messageDataPlan === 'Token is invalid') {
    //     dispatch(logout())
    //     toast.error('Error!! Please Login.')
    //   }
    // }
    if (isSuccessAllUsers || isErrorAllUsers) {
      dispatch(resetAllUsers())
    }
    if (isSuccessDataPlan || isErrorDataPlan) {
      dispatch(resetDataPlan())
    }

  }, [messageDataPlan, isSuccessDataPlan, isMessageAllUsers, isSuccessAllUsers])

  const getUserDataStat = async () => {
    try {
      setLoading(true)
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'

      };
      const response = await axios.get(baseApiUrl + '/api/data/get-user-stat', { headers })
      setdataTransxn(response.data)
    } catch (error) {
      if (error.response.data === 'token expired' || error.response.data === "Token is invalid") {
        toast.error('Error!! Please Login..')
        dispatch(logout())
      } else {
        toast.error(error.response.data)
      }
    } finally {
      setLoading(false)
    }
  }

  const getAdminDataStat = async () => {
    try {
      setLoading(true)
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'

      };
      const response = await axios.get(baseApiUrl + '/api/data/get-admin-stat', { headers })
      setdataTransxn(response.data)
    } catch (error) {
      if (error.response.data === 'token expired' || error.response.data === "Token is invalid") {
        toast.error('Error!! Please Login')
        dispatch(logout())
      } else {
        toast.error(error.response.data)
      }
    } finally {
      setLoading(false)
    }
  }

  const getUserAirtimeStat = async () => {
    try {
      setIsLoading(true)
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'

      };
      const response = await axios.get(baseApiUrl + '/api/airtime/get-user-stat', { headers })
      setAirtimeTransxn(response.data)
    } catch (error) {
      if (error.response.data === 'token expired' || error.response.data === "Token is invalid") {
        // toast.error('Error!! Please Login..')
        // dispatch(logout())
      } else {
        toast.error(error.response.data)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getAdminAirtimeStat = async () => {
    try {
      setIsLoading(true)
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'

      };
      const response = await axios.get(baseApiUrl + '/api/airtime/get-admin-stat', { headers })
      setAirtimeTransxn(response.data)
    } catch (error) {
      if (error.response.data === 'token expired' || error.response.data === "Token is invalid") {
        // toast.error('Error!! Please Login')
        // dispatch(logout())
      } else {
        toast.error(error.response.data)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getAdminFundedAmount = async () => {
    try {
      setIsFunding(true)
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'

      };
      const response = await axios.get(baseApiUrl + '/api/wallet/get-all-wallet-histories', { headers })
      if (response.data) {
        setAllWalletTransactions(response.data)
      }
    } catch (error) {
      if (error.response.data === 'token expired') {
        // toast.error('Error!! Please Login again')
        // dispatch(logout())
      } else if (error.response) {
        toast.error(error.response.data)
      } else {
        toast.error(error)
      }
    } finally {
      setIsFunding(false)
    }
  }

  const getUserFundedAmount = async () => {
    try {
      setIsFunding(true)
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'

      };
      const response = await axios.get(baseApiUrl + '/api/wallet/get-wallet-history', { headers })
      if (response.data) {
        setAllWalletTransactions(response.data)
      }
    } catch (error) {
      if (error.response.data === 'token expired') {
        // toast.error('Error!! Please Login again')
        // dispatch(logout())
      } else if (error.response) {
        toast.error(error.response.data)
      } else {
        toast.error(error)
      }
    } finally {
      setIsFunding(false)
    }
  }

  useEffect(() => {
    if (user?.isAdmin) {
      dispatch(getAllUsers())
      getAdminDataStat()
      getAdminAirtimeStat()
      getAdminFundedAmount()
    } else {
      dispatch(getAllDataPlan())
      getUserDataStat()
      getUserAirtimeStat()
      getUserFundedAmount()
    }
  }, [])

  //calculate total data
  const totalData = dataTransxn?.reduce((sum, transaction) => {
    const { volume, unit } = transaction.dataPlan

    let standardVolume = volume
    if (unit === 'mb') {
      standardVolume /= 1024
    }

    return sum + standardVolume
  }, 0)

  //calculate total amount
  const totalAmount = dataTransxn?.reduce((sum, transaction) => transaction?.amount !== undefined ? sum + transaction?.amount : sum + transaction.dataPlan.amount, 0)

  //calculate total airtime
  const totalAirtime = airtimeTransxn?.reduce((sum, transaction) => transaction?.airtime !== undefined ? sum + transaction.airtime : sum, 0)

  //calculate total airtime amount
  const totalAirtimeAmount = airtimeTransxn?.reduce((sum, transaction) => sum + transaction.amount, 0)

  //calculate funded amount
  const fundedAmount = walletTransactions?.filter((data) => data.transactionType === 'Funding').reduce((sum, transaction) => sum + transaction.amount, 0)


  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <div class="w-100 d-md-flex justify-content-between align-items-center px-5 my-5">
          <h2 className="col-12 col-md-8 fs-2 text-center text-md-left ">Welcome to FLIPRECHARGE</h2>
          {user?.isAdmin ? null : <div style={{ background: "#ff5722", borderRadius: "5rem", cursor: "pointer" }} className='col-12 col-md-4 col-lg-3 col-xl-2 text-center text-white px-2 py-2' onClick={() => navigate('/user/fund-wallet')}>Fund Wallet</div>}
        </div>
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              {!user?.isAdmin && <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-4">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Wallet Balance
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          ₦{WalletBalance?.balance?.toFixed(2)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          {/* <i className="fas fa-chart-bar" /> */}
                          <FontAwesomeIcon icon={faWallet} />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>}
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-4">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Data
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{loading ? <ProgressSpinner style={{ width: '30px', height: '30px' }} /> : totalData.toFixed(2) > 1024 ? (totalData / 1024).toFixed(2) : totalData.toFixed(2)}{totalData.toFixed(2) > 1024 ? 'TB' : 'GB'}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-4">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total amount(Data)
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">₦{loading ? <ProgressSpinner style={{ width: '30px', height: '30px' }} /> : totalAmount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 1.10%
                      </span>{" "}
                      <span className="text-nowrap">Since yesterday</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-4">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {user?.isAdmin ? 'Registered Users' : 'Total number of people'}
                        </CardTitle>
                        {user?.isAdmin ? <span className="h2 font-weight-bold mb-0">{loading ? <ProgressSpinner style={{ width: '30px', height: '30px' }} /> : AllUsers?.length}</span> : <span className="h2 font-weight-bold mb-0">{loading ? <ProgressSpinner style={{ width: '30px', height: '30px' }} /> : dataTransxn?.length + airtimeTransxn?.length}</span>}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 12%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-4">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Airtime
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">₦{isLoading ? <ProgressSpinner style={{ width: '30px', height: '30px' }} /> : totalAirtime.toFixed(2)}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i className="fas fa-phone" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-4">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Amount(Airtime)
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">₦{isLoading ? <ProgressSpinner style={{ width: '30px', height: '30px' }} /> : totalAirtimeAmount.toFixed(2)}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gray text-white rounded-circle shadow">
                          <i className="fas fa-dollar" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-4">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Amount Funded
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">₦{isFunding ? <ProgressSpinner style={{ width: '30px', height: '30px' }} /> : fundedAmount.toFixed(2)}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fas fa-bank" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span> */}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;