import React from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import Spinner from "../../../Component/Spinner";
import { Helmet } from 'react-helmet-async'
import axios from 'axios';
import { baseApiUrl } from '../../../Utils/constants';

const Forgot_Password = () => {
  const {id, token} = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
  
    const [formData, setFormData] = useState({
      password: "",
      confirm_password: ""
  })
  
  const {password, confirm_password} = formData

  useEffect(()=> {
    const verifyToken = async()=> {
      try {
        setIsLoading(true)
        const response = await axios.get(baseApiUrl + '/user/reset-password/' + id + '/' + token)
        toast.success(response.data)
      } catch (error) {
        // navigate('/auth/login')
        toast.error(error.response.data)
      } finally {
        setFormData({
          password: "",
          confirm_password: ""
        })
        setIsLoading(false)
      }
    }
    verifyToken()
  }, [])

    const onChange = (e) => {
        setFormData((prevState)=> ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
      }

    const resetPassword = async (id, token, newPassword)=> {
      try {
        setIsLoading(true)
        const response = await axios.put(baseApiUrl + '/user/reset-password/' + id + '/' + token, newPassword)
        toast.success(response.data)
        navigate('/auth/login')
      } catch (error) {
        toast.error(error.response.data)
      } finally {
        setFormData({
          password: "",
          confirm_password: ""
        })
        setIsLoading(false)
      }
    }

    const onSubmit = (e) => {
       e.preventDefault()
       if(!password || !confirm_password){
         toast.error('Input new password in both field')
       }else if(password !== confirm_password){
         toast.error('Password does not match')
       }else {
         resetPassword(id, token, {password: password})
       }
      }
  return (
    <>
    <Helmet defer={false}>
        <title>Telcom || Reset Password</title>
    </Helmet>
    {isLoading && <Spinner />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small style={{fontSize: "20px"}}>Create a new Password</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    required
                    autoComplete="new-password"
                    name="password"
                    value={password}
                    onChange={onChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    required
                    autoComplete="new-password"
                    name="confirm_password"
                    value={confirm_password}
                    onChange={onChange}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" onClick={onSubmit} type="button">
                Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  )
}

export default Forgot_Password