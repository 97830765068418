import {useEffect, useRef, useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.js";
import { Button, Form, Input, Select, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../../../features/Auth/AuthSlice.jsx";
import { createDataPlan, dataPackage, deleteDataPlanById, getAllDataPlan, resetDataPlan } from "../../../features/DataPlan/DataPlanSlice.jsx";
import { toast } from "react-toastify";
import Modaldiv from "./Modaldiv.js";
import Spinner from "../../../Component/Spinner.jsx";
import { baseApiUrl } from '../../../Utils/constants';
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'

const Data_Plan = () => {
    const formRef = useRef(null)
    const gridRef = useRef(null)
    const dispatch = useDispatch()
    const {user} = useSelector(getUser)
    const {dataPlans, isErrorDataPlan, isLoadingDataPlan, isSuccessDataPlan, messageDataPlan} = useSelector(dataPackage)
  
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [created, setCreated] = useState(false)
    const [prompt, setPrompt] = useState(false)
    const [editData, setEditData] = useState([])
    const [deleteData, setDeleteData] = useState([])
    const [isAddPlanOpen, setIsAddPlanOpen] = useState(false)
    const [isDeletePlanOpen, setIsDeletePlanOpen] = useState(false)
    const [defaultActiveProvider, setDefaultActiveProvider] = useState('')
    const [globalFilterValue, setGlobalFilterValue] = useState('');


    useEffect(()=> {
      if (created && isSuccessDataPlan){
        dispatch(getAllDataPlan())
        formRef.current.resetFields();
        gridRef.current.scrollIntoView({behavior: "smooth"}) 
        setIsAddPlanOpen(false)
        toast.success('Data Plan Created')
      }
      if (created && isLoadingDataPlan) {
        toast.error(messageDataPlan)
      }
      if(prompt && isLoadingDataPlan) {
        toast.info('Deleting...')
      }
      if(prompt && isSuccessDataPlan) {
        dispatch(getAllDataPlan())
        setIsDeletePlanOpen(false)
        toast.success('Data Plan Deleted')
      }
      if(prompt && isErrorDataPlan) {
        toast.error(messageDataPlan)
      }
      if(messageDataPlan === 'token expired' || messageDataPlan === 'Token is invalid'){
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (messageDataPlan !== ''){
        toast.error(messageDataPlan)
      }
      dispatch(resetDataPlan())
      setCreated(false)
      setPrompt(false)
    }, [isSuccessDataPlan, isErrorDataPlan])

    useEffect(()=> {
      dispatch(getAllDataPlan())
      getActiveProvider()
    }, [])

    const onFinish = (formData) => {
        setCreated(true)
        dispatch(createDataPlan(formData))
    }

    const filteredData = dataPlans.filter((data)=> {
      const searchText = `${data?.provider?.name} ${data?.network} ${data?.unit} ${data?.validity} ${data?.volume}`.toLowerCase()
      return searchText.includes(globalFilterValue.toLowerCase())
    })

      const setActiveProvider = async(formData) => {
        try {
          toast.info('Processing...')
          const headers = {
              'Authorization': `Bearer ${user?.accessToken}`,
              'Content-Type': 'application/json'
          };
          const response = await axios.post(baseApiUrl + "/api/dataplan/set-provider-active", formData, { headers })
          if (response.data) {
              toast.success(response.data)
              dispatch(getAllDataPlan())
          }
      } catch (error) {
          if (error.response.data === 'token expired' || error.response.data === 'Token is invalid') {
            toast.error('Error!! Please login')
              dispatch(logout())
          } else if (error.response) {
              toast.error(error.response.data)
          } else {
              toast.error(error)
          }
      }
    }

    const getActiveProvider = async()=> {
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'
      };
      try {
        const response = await axios.get(baseApiUrl +  '/api/dataplan/get-active-provider', {headers})
        if(response.data){
          setDefaultActiveProvider(response.data)
        }
      } catch (error) {
        if(error.response.data === 'token expired' || error.response.data === "Token is invalid") {
          toast.error('Error!! Please Login again')
          dispatch(logout())
        } else if(error.response){
          toast.error(error.response.data)
        } else {
          toast.error(error)
        }
      }
    }
     
    const onActive = async(formData) => {
      if(!formData){
        toast.error('Please Select Data Provider')
      } else {
        setActiveProvider(formData)
      }
    }

    const onEdit =(dataId)=> {
        const dataToEdit = dataPlans.filter((dataPlan)=> dataId === dataPlan?._id)
        if(dataToEdit){
        setEditData(dataToEdit)
        setIsModalOpen(true)
        }
    }

    const onDelete = (dataId)=> {
        const dataToDelete = dataPlans.filter((dataPlan)=> dataId === dataPlan?._id)
        if(dataToDelete){
          setDeleteData(dataToDelete)
          setIsDeletePlanOpen(true)
        }
  }

  const editColumnTemplate = (rowData) => {
    return (
      <i className="fas fa-pen-to-square mx-4 text-primary" style={{cursor: "pointer"}} 
      onClick={()=>onEdit(rowData?._id)}/>
    );
  };
  
  const deleteColumnTemplate = (rowData) => {
    return (
      <i className="fa-sharp fa-solid fa-trash text-danger" style={{cursor: "pointer"}}
        onClick={() => onDelete(rowData?._id)}
      />
    );
  };

  const networkColumnTemplate = (rowData) => {
    return rowData?.network.toUpperCase()
  }

  const volumeColumnTemplate = (rowData) => {
    return rowData?.volume + rowData?.unit
  }

  const durationColumnTemplate = (rowData)=> {
    return rowData?.validity + 'days'
  }

  const providerColumnTemplate = (rowData)=> {
    return rowData?.provider?.name
  }

  const deleteButton = () => {
    const data = deleteData.find(data=> data._id)
    const dataId = data._id
    setPrompt(true)
    dispatch(deleteDataPlanById(dataId))
  }

  

 
  return (
    <div>
        <Helmet defer={false}>
        <title>Fliprecharge || Data Plan</title>
      </Helmet>
      {isLoadingDataPlan && <Spinner />}
     <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{height : "70vh"}}></div>
      <Modaldiv editData={editData} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
      <Modal open={isDeletePlanOpen} onOk={deleteButton} onCancel={()=>setIsDeletePlanOpen(false)} title='' className="" width={700} centered  >
         {deleteData?.map(data=> 
          <Card key={data?._id} className="shadow mt-4">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Delete Data Plan</h3>
              </CardHeader>
              <CardBody>
                <p>{'Are you sure you want to delete this ' + data?.network.toUpperCase() + ' plan?'}</p>
              </CardBody>
          </Card>
          )}
       </Modal>  
      {/* Page content */}
      <div className="mx-auto px-md-4 d-flex justify-content-between flex-wrap" style={{width: "94%", marginTop: "-18rem"}}>
      <Container className="mx-0 alert alert-success d-flex justify-content-center align-items-center col-12 col-md-4" onClick={()=>setIsAddPlanOpen(true)} style={{ width: "18rem", cursor: "pointer"}}  fluid><i className="fas fa-solid fa-plus text-white mr-2"></i>Add a new Data Plan</Container>
      {!defaultActiveProvider && <Spinner />}
      {defaultActiveProvider && 
      <Form onFinish={onActive} className='d-flex flex-wrap align-items-center justify-content-md-end justify-content-center col-12 col-md-6'>
      <label htmlFor=""><h3 className="text-white">Provider:</h3></label>
          <Form.Item initialValue={defaultActiveProvider} className="col-4" name="name" rules={[{required: 'true', message: "Please input Provider"}]}>
            <Select>
              <Select.Option value='sme'>SME</Select.Option>
              <Select.Option value='tranzit'>TRANZIT</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button className="bg-success text-white" htmlType="submit">Submit</Button> 
          </Form.Item>
      </Form>}
      </div>
      <Modal open={isAddPlanOpen} footer={null} onCancel={()=> setIsAddPlanOpen(false)} width={700} title='' className="my-3"  centered>
      <Container className="mx-0" style={{marginTop:''}} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="my-4">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Add Data Plan</h3>
              </CardHeader>
              <CardBody>
          <Form ref={formRef} className='w-100 bg-white p-2 rounded-xl' onFinish={onFinish}>
          <label htmlFor="">Name</label>
          <Form.Item>
            <Input disabled value={user?.username}/>
          </Form.Item>
          <label htmlFor="">Provider</label>
          <Form.Item name="provider" rules={[{required: 'true', message: "Please input Provider"}]}>
            <Select>
              <Select.Option value='sme'>SME</Select.Option>
              <Select.Option value='tranzit'>TRANZIT</Select.Option>
              <Select.Option value='glo'>GLO</Select.Option>
            </Select>
          </Form.Item>
          <label htmlFor="">Network</label>
          <Form.Item name="network" rules={[{required: 'true', message: "Please input Network"}]}>
            <Select>
              <Select.Option value='mtn'>MTN</Select.Option>
              <Select.Option value='9mobile'>9Mobile</Select.Option>
              <Select.Option value='glo'>Glo</Select.Option>
              <Select.Option value='airtel'>Airtel</Select.Option>
            </Select>
          </Form.Item>
          <label htmlFor="">Plan Name</label>
          <Form.Item name="planName" required={false}>
            <Input />
          </Form.Item>
          <label htmlFor="">Plan ID</label>
          <Form.Item name="planId" required={false}>
            <Input type="number" />
          </Form.Item>
          <label htmlFor="">Product Code Or Plan(Tranzit)</label>
          <Form.Item name="productCode" required={false}>
            <Input />
          </Form.Item>
          <label htmlFor="">Volume</label>
          <Form.Item name="volume" rules={[{required: 'true',  message: "Please input Data Volume"}]}>
            <Input type="number"/>
          </Form.Item>
          <label htmlFor="">Unit</label>
          <Form.Item name="unit" rules={[{required: 'true',  message: "Please input Data Unit"}]}>
            <Select>
              <Select.Option value="mb">mb</Select.Option>
              <Select.Option value="gb">gb</Select.Option>
            </Select>
          </Form.Item>
          <label htmlFor="">Data Type</label>
          <Form.Item name="type" rules={[{required: 'true',  message: "Please input Data Type"}]}>
            <Select>
              <Select.Option value='gifting'>Gifting</Select.Option>
              <Select.Option value='sme'>SME</Select.Option>
              <Select.Option value='corporate gifting'>Corporate Gifting</Select.Option>
            </Select>
          </Form.Item>
          <label htmlFor="">Validity</label>
          <Form.Item name="validity" rules={[{required: 'true', message: "Please input Validity"}]}>
            <Input type="number" placeholder="Duration in days" 
              suffix={
                <Tooltip title="Duration in days">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
          </Form.Item>
          <label htmlFor="">Amount</label>
          <Form.Item name="amount" rules={[{required: 'true', message: "Please input Amount"}]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item>
          <Button className="bg-gradient-info text-white" htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      </Modal>
      <Container className="mb-5 mx-auto" style={{width: "94%"}} fluid>
        <Row>
        <Card className="shadow" style={{width: '100%'}}>
        <div className='col'>
          <CardHeader>
            <h3>Data Plans</h3> 
          </CardHeader>
            <div ref={gridRef}>
            {dataPlans && 
            <div><InputText placeholder='search' className='searchdrop p-2 border w-50 my-2'  value={globalFilterValue}
            onChange={(e) => setGlobalFilterValue(e.target.value)} />
            <DataTable value={filteredData}  responsiveLayout='scroll' paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
              <Column field='network' header='Network'  body={networkColumnTemplate}></Column>
              <Column field='type' header='Type'></Column>
              <Column field='volume' header='Volume'  body={volumeColumnTemplate}></Column>
              <Column field='validity' header='Duration'  body={durationColumnTemplate}></Column>
              <Column header='Provider' body={providerColumnTemplate}></Column>
              <Column header='' body={editColumnTemplate}></Column>
              <Column header='' body={deleteColumnTemplate}></Column>
            </DataTable>
            </div>}
            </div>
        </div>
        </Card>
        </Row>
      </Container>
    </div>
  )
}

export default Data_Plan