import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardHeader, Container, Row } from 'reactstrap'
import { airtimeTransactions, getAllAirtimeTransaction, resetAirtimeTransactions } from '../../../features/AirtimeTransaction/AirtimeTransactionSlice'
import { InputText } from 'primereact/inputtext'
import { Input, Space } from 'antd';
import { allUsers, getAllUsers } from '../../../features/Users/UsersSlice'
import { FilterMatchMode } from 'primereact/api'
import {Tag} from 'primereact/tag'
import { toast } from 'react-toastify'
import Spinner from '../../../Component/Spinner'
import { logout } from '../../../features/Auth/AuthSlice'
import * as XLSX from 'xlsx';


const UserAirtimeTransaction = () => {
  const { Search } = Input;
  const [userId, setUserId] = useState("")
  const dispatch = useDispatch()
  const [tableValue, setTablevalue] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('');


  const { AllUsers, isLoadingAllUsers, isSuccessAllUsers, isErrorAllUsers, isMessageAllUsers } = useSelector(allUsers)
  const { airtimeTransaction, airtimeTransactionNetwork, isSuccessAirtimeTranxn, isErrorAirtimeTranxn, messageAirtimeTranxn, isLoadingAirtimeTranxn } = useSelector(airtimeTransactions)

  
     //Table template format

    const filteredData = tableValue?.filter((data)=> {
      const searchText = `${data?.productCode}`.toLowerCase()
      return searchText.includes(globalFilterValue.toLowerCase())
    })

    const getUsername = (userId) => {
      const User = AllUsers?.find(username => username?._id === userId?.user)
      return User?.username
    }

    const networkFormat = (dataplan)=> {
      if(dataplan.productCode.toUpperCase().includes("9mobile".toUpperCase())){
        return "9Mobile"
      }else if(dataplan.productCode.toUpperCase().includes("mtn".toUpperCase())) {
        return "MTN"
      }
      else if(dataplan.productCode.toUpperCase().includes("airtel".toUpperCase())){
        return "Airtel"
      } else if(dataplan.productCode.toUpperCase().includes("glo".toUpperCase())) {
        return "Glo"
      }
    }

    const dateFormat = (date) => {
      const dateFromMongoDB = new Date(date);
  
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Use 12-hour clock format
      };
  
      const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);
  
      return formatted
  }

  const dateFormatTemplate = (dataTransaction)=> {
    return dateFormat(dataTransaction?.createdAt)
}

const statusBodyTemplate = (dataTransaction) => {
  return <Tag value={dataTransaction.status} severity={getSeverity(dataTransaction)}></Tag>;
};

const getSeverity = (dataTransaction) => {
  switch (dataTransaction.status) {
      case 'completed':
          return 'success';

      case 'pending':
          return 'warning';

      case 'failed':
          return 'danger';

      default:
          return null;
  }
};

  useEffect(()=>{
    if(userId){
      dispatch(getAllAirtimeTransaction(userId))
    }
  }, [userId])

    //get users
    useEffect(()=> {
      dispatch(getAllUsers())
  }, [])


   //search usernames
   const onSearch = (value) => {
    console.log(value.trim().toLowerCase())
    if(AllUsers){
        let userDetails =  AllUsers?.find((data)=> data?.username === value.trim().toLowerCase())
        if(userDetails){
          setUserId(userDetails?._id)
        } else {
          toast.error("Input a valid username")
          setTablevalue([])
        }
     }
}

useEffect(()=> {
  if(isErrorAirtimeTranxn){
    setTablevalue([])
  }
  if(isSuccessAirtimeTranxn){
    setTablevalue(airtimeTransaction)
  }
  if (messageAirtimeTranxn === 'token expired' || messageAirtimeTranxn === "Token is invalid") {
    toast.error('Error!! Please Login again')
    dispatch(logout())
  } else if (messageAirtimeTranxn !== '') {
    toast.error(messageAirtimeTranxn)
  }

  if (isSuccessAirtimeTranxn || isErrorAirtimeTranxn) {
    dispatch(resetAirtimeTransactions())
  }
    
}, [isSuccessAirtimeTranxn, isErrorAirtimeTranxn])

const exportToExcel = () => {
  // Create a new workbook instance
  const workbook = XLSX.utils.book_new();

  // Convert the data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(filteredData.map(transaction => ({
      Date: dateFormat(transaction.createdAt),
      User: getUsername({user: transaction.user}),
      Network: networkFormat({productCode: transaction.productCode}),
      Airtime: transaction.airtime || 0,
      Amount: transaction.amount,
      'Mobile Number': transaction.msisdn,
      Status: transaction.status,
  })));

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "AirtimeTransactions");

  // Write the workbook and trigger a download
  XLSX.writeFile(workbook, "UserAirtimeTransactions.xlsx");
};

// console.log(userId)
// console.log(tableValue)
  return (
    <div>
      <Helmet defer={false}>
      <title>Fliprecharge || All Airtime Transactions</title>
    </Helmet>
    {isLoadingAllUsers && <Spinner />}
    {isLoadingAirtimeTranxn && <Spinner />}
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{height : "70vh"}}></div>
        {/* Page content */}
    <Container className="" style={{marginTop: "-22rem"}} fluid>
        <div className='m-3'><Search placeholder='Enter Username' onChange={()=> setUserId("")} onSearch={onSearch} style={{ width: 200, }} />

        </div>
        <Row>
        <div className="col">
            <Card className="shadow px-3 pb-4">
                <CardHeader className="bg-transparent">
                <h3 className="mb-0">User Data Transactions</h3>
                </CardHeader>
                <div>
                    {isLoadingAirtimeTranxn ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search network' className='searchdrop p-2 border w-25 my-4' value={globalFilterValue} onChange={(e)=> setGlobalFilterValue(e.target.value)} />
                    <button className="btn btn-success left-3" onClick={exportToExcel}>Export to Excel</button>

                <DataTable value={filteredData}   responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                    <Column header='Date' field='createdAt' body={dateFormatTemplate} ></Column>
                    <Column header='User' field='user' body={getUsername} ></Column>
                    <Column header='Network' field='network' body={networkFormat}></Column>
                    <Column header='Airtime' field='airtime' ></Column>
                    <Column header= 'Amount' field= 'amount' ></Column>
                    <Column header='Mobile Number' field='msisdn' ></Column>
                    <Column header='Status' field='status' body={statusBodyTemplate}></Column>
                </DataTable></div>}
                </div>
            </Card>
        </div>
        </Row>  
    </Container>

    </div>
  )
}

export default UserAirtimeTransaction