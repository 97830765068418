import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Spinner from "../../../Component/Spinner.jsx";
import { getUser, logout } from "../../../features/Auth/AuthSlice.jsx";
import { baseApiUrl } from "../../../Utils/constants.js";
// core components
import UserHeader from "../../components/Headers/UserHeader.js";


const Settings = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(getUser)
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"
  const changePasswordApi = '/user/update-password/'

  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  })

  const { oldPassword, newPassword, confirmNewPassword } = formData


  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const changePassword = async (data) => {
    try {
      setIsLoading(true)
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'

      };
      const response = await axios.put(`${baseApiUrl}${changePasswordApi}${user?._id}`, data, { headers })
      toast.success(response.data)
    } catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        dispatch(logout())
        toast.error('Error!! Please Login again')
      } else if (error.response) {
        toast.error(error.response.data)
      } else {
        toast.error(error)
      }
    } finally {
      setFormData({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      })
      setIsLoading(false)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (!oldPassword) {
      toast.error('Please input your password')
    }
    else if (!newPassword) {
      toast.error('Please input your new password')
    }
    else if (!confirmNewPassword) {
      toast.error('Please confirm new password')
    } else if (oldPassword === newPassword) {
      toast.error('Old and New Password cannot be the same')
    } else if (newPassword !== confirmNewPassword) {
      toast.error('Both New Password does not match')
    } else {
      const data = { oldPassword, newPassword }
      changePassword(data)
    }
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Telecom || Settings</title>
      </Helmet>
      {isLoading && <Spinner />}
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Settings</h3>
                  </Col>
                  {/* <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Change Password
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="oldPassword"
                          >
                            Old Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            required
                            name="oldPassword"
                            value={oldPassword}
                            id="oldPassword"
                            placeholder="Old Password"
                            type="password"
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="newPassword"
                          >
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            required
                            name="newPassword"
                            value={newPassword}
                            id="newPassword"
                            placeholder="New Password"
                            type="password"
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="confirmNewPassword"
                          >
                            Confirm New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            required
                            name="confirmNewPassword"
                            value={confirmNewPassword}
                            id="confirmNewPassword"
                            placeholder="Confirm New Password"
                            type="password"
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" lg="2 mt-lg-4 pt-lg-2">
                        <FormGroup>
                          {/* <label
                              className="form-control-label invisible"
                              htmlFor="input-last-name"
                            >
                              Submit Submit
                            </label> */}
                          <Button
                            color="primary"
                            href="#pablo"
                            onClick={onSubmit}
                          >
                            Submit
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  {/* <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                              id="input-address"
                              placeholder="Home Address"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="New York"
                              id="input-city"
                              placeholder="City"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="United States"
                              id="input-country"
                              placeholder="Country"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              placeholder="Postal code"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">About me</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="A few words about you ..."
                          rows="4"
                          defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                          type="textarea"
                        />
                      </FormGroup>
                    </div> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
