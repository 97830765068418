import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AirtimeTransactionService from './AirtimeTransactionService'

const initialState = {
    airtimeTransaction: [],
    isErrorAirtimeTranxn: false,
    isSuccessAirtimeTranxn: false,
    isLoadingAirtimeTranxn: false,
    messageAirtimeTranxn: "",
    airtimeTransactionNetwork: "",
}

//get all airtime transactions
export const getAllAirtimeTransaction = createAsyncThunk('airtimetransaction/user', async(userId, thunkAPI)=> {
    try {
       const accesstoken = thunkAPI.getState().auth.user.accessToken
       return await AirtimeTransactionService.findAllAirtimeTransaction(accesstoken, userId)
    } catch (error) {
        const message = error.response.data
        return thunkAPI.rejectWithValue(message)
    }
})

const AirtimeTransactionSlice = createSlice({
  name: "airtimeTransaction",
  initialState,
  reducers: {
    resetAirtimeTransactions: (state) => {
        state.isLoadingAirtimeTranxn = false
        state.isErrorAirtimeTranxn= false
        state.isSuccessAirtimeTranxn= false
        state.messageAirtimeTranxn=""
    },
    airtimeTransactionByDates: (state, action) => {
        state.airtimeTransaction = action.payload
    },
    setAirtimeTransactionNetwork: (state, action) => {
        state.airtimeTransactionNetwork = action.payload
    }, 
  }, extraReducers: (builder) => {
    builder
    .addCase(getAllAirtimeTransaction.pending, (state)=> {
        state.isLoadingAirtimeTranxn = true
    })
    .addCase(getAllAirtimeTransaction.fulfilled, (state, action)=> {
        state.isLoadingAirtimeTranxn = false
        state.isSuccessAirtimeTranxn = true
        state.airtimeTransaction = action.payload 
    })
    .addCase(getAllAirtimeTransaction.rejected, (state, action)=> {
        state.isLoadingAirtimeTranxn = false
        state.isErrorAirtimeTranxn = true
        state.messageAirtimeTranxn = action.payload
    })
}
});

export const airtimeTransactions = state => state.airtimeTransaction

export const {resetAirtimeTransactions, airtimeTransactionByDates, setAirtimeTransactionNetwork} = AirtimeTransactionSlice.actions

export default AirtimeTransactionSlice.reducer