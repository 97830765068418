import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";
import { Helmet } from 'react-helmet-async'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { useDispatch, useSelector } from 'react-redux';
import { allUsers, getAllUsers, resetAllUsers } from '../../../features/Users/UsersSlice';
import { toast } from 'react-toastify';
import { getUser, logout } from '../../../features/Auth/AuthSlice';
import axios from 'axios';
import { baseApiUrl } from '../../../Utils/constants';
import Spinner from '../../../Component/Spinner';
import { Button, Form, Input, Modal } from 'antd';

const FundUserWallet = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const [openFundModal, setOpenFundModal] = useState(false)
    const [userData, setUserData]= useState()
    const [confirmModal, setConfirmModal] = useState(false)
    const [fundingDetails, setFundingDetails] = useState()
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)

    const [filter, setFilter] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    })

    const { AllUsers, isLoadingAllUsers, isSuccessAllUsers, isErrorAllUsers, isMessageAllUsers } = useSelector(allUsers)

    const dateFormat = (date) => {
        const dateFromMongoDB = new Date(date);
    
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true, // Use 12-hour clock format
        };
    
        const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);
    
        return formatted
    }

    const dateFormatTemplate = (users) => {
        return dateFormat(users?.createdAt)
    }
    
    const fundWalletFormat = (user)=> {
        return (
           !user?.isAdmin ?  <div className='bg-gradient-info text-white text-center rounded-2 p-2' style={{cursor: 'pointer'}} onClick={()=> userToFund(user)}>Fund Wallet</div> : <div className='text-center'>No wallet</div>
        )
    }

    const userToFund = (user)=> {
        setUserData(user)
        setOpenFundModal(true)
    }

    const confrimFundWallet = (amount) => {
        const formData = {amount: Number(amount?.amount), userId: userData?._id}
        setFundingDetails(formData)
       if(fundingDetails){
        setOpenFundModal(false)
        setConfirmModal(true)
       }
    }

    const addAmount = async() => {
        try {
            setIsLoading(true)
            const headers = {
                'Authorization': `Bearer ${user?.accessToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(baseApiUrl + "/api/wallet/admin-fund-wallet", fundingDetails, { headers })
            if (response.data) {
                toast.success(response.data.message)
                form.resetFields()
            }
        } catch (error) {
            if (error.response.data === 'token expired' || error.response.data === 'Token is invalid') {
                dispatch(logout())
            } else if (error.response) {
                toast.error(error.response.data)
            } else {
                toast.error(error)
            }

        } finally {
            setOpenFundModal(false)
            setConfirmModal(false)
            setIsLoading(false)
        }
    }

    const onCancel = () => {
        setOpenFundModal(false)
        setConfirmModal(false)
        form.resetFields()
    }

    useEffect(() => {
        dispatch(getAllUsers())
    }, [])

    useEffect(() => {
        if (isMessageAllUsers === 'token expired' || isMessageAllUsers === 'Token is invalid') {
            toast.error('Error!! Please Login again')
            dispatch(logout())
        } else if (isMessageAllUsers !== '') {
            toast.error(isMessageAllUsers)
        }

        if (isSuccessAllUsers || isErrorAllUsers) {
            dispatch(resetAllUsers())
        }

    }, [isSuccessAllUsers, isErrorAllUsers])


  return (
    <div>
    <Helmet defer={false}>
            <title>Fliprecharge || Fund User Wallet</title>
        </Helmet>
        {/* {loadingWallet && <Spinner />} */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
        {/* Page content */}
        <Modal open={openFundModal} onCancel={onCancel} title='Add Amount' centered footer={null}>
            <Form form={form} onFinish={confrimFundWallet}>
                <label>You are funding the wallet of {userData?.username}</label>
                <Form.Item name='amount' rules={[{required: 'true',  message: "Please input amount"}]}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item className='d-flex justify-content-end mt-4'>
                <Button className="bg-gradient-info text-white" htmlType="submit">Fund Wallet</Button>
                </Form.Item>
            </Form>
        </Modal>
        <Modal open={confirmModal} onCancel={onCancel} title='Fund User Wallet' onOk={addAmount} okText="Yes" cancelText="No" centered>
            <label>Are you sure you want to fund the wallet of {userData?.username}</label>
        </Modal>
        {isLoadingAllUsers && <Spinner />}
        {isLoading && <Spinner />}
        <Container className="" style={{ marginTop: "-22rem" }} fluid>
            {/* Table */}
            <Card className="shadow px-3 pb-4">
                <CardHeader className="bg-transparent">
                    <h3 className="mb-0">All Registered Users</h3>
                </CardHeader>
                {isLoadingAllUsers ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search username' className='searchdrop p-2 border w-25 my-4' onInput={(e) => setFilter({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS }, })} />
                    <DataTable value={AllUsers} filters={filter} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                        <Column field='createdAt' header='Date' body={dateFormatTemplate} style={{ width: '20%' }}></Column>
                        <Column field='username' header='Username' style={{ width: '20%' }}></Column>
                        <Column field='phonenumber' header='Phone Number' style={{ width: '20%' }}></Column>
                        {/* <Column field='isAdmin' header='Admin' ></Column> */}
                        <Column header='' body={fundWalletFormat} style={{ width: '20%' }}></Column>
                    </DataTable></div>}
            </Card>
        </Container>
    </div>
  )
}

export default FundUserWallet