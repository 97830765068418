import axios from "axios";
import { baseApiUrl } from "../../Utils/constants";

const API = `${baseApiUrl}/api/wallet/`


//getWallet History
const getWalletHistory = async(accesstoken)=> {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`
        }
    }
    const response = await axios.get(`${API}get-wallet-history`, config)
    return response.data
}

//get wallet balance
const getWalletBalance = async(accesstoken)=> {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`
        }
    }
    const response = await axios.get(`${API}get-wallet-history`, config)
    return response.data
}

const WalletService = {
     getWalletHistory,
     getWalletBalance
}

export default WalletService