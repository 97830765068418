import { createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import WalletService from './WalletService'



const balance = JSON.parse(localStorage.getItem("userTelecomBalance"))

//get wallet balance
export const userWalletBalance = createAsyncThunk('walletbalance/get', async(_, thunkAPI)=> {
  try {
    const accessToken = thunkAPI.getState().auth.user.accessToken
    return await WalletService.getWalletBalance(accessToken)
  } catch (error) {
    const message = error.response.data
    return thunkAPI.rejectWithValue(message)
  }
})


const WalletSlice = createSlice({
  name: "wallet",
  initialState : {
    balance: balance ? balance : 0,
    fundWalletOption: ''
  },
  reducers: {
    updateWalletBalance: (state, action) => {
        state.balance = action.payload;
        localStorage.setItem("userTelecomBalance", JSON.stringify(action.payload))
      },
    updateFundWalletOption: (state, action)=> {
      state.fundWalletOption = action.payload;
    }
  },
});

export const walletBalance = state => state.wallet

export const {updateWalletBalance, updateFundWalletOption} = WalletSlice.actions

export default WalletSlice.reducer