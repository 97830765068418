/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
// import Header from "../../components/Headers/Header.js";
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {FilterMatchMode} from 'primereact/api'
import { Tag } from 'primereact/tag'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allPayments, getAllPayment, resetAllPayment } from "../../../features/Payments/AllPaymentSlice";
import { toast } from "react-toastify";
import { logout } from "../../../features/Auth/AuthSlice";
import { allUsers, getAllUsers, resetAllUsers } from "../../../features/Users/UsersSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../Component/Spinner";

const All_Payments = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedPayments, setSelectedPayments] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { allPayment, isLoadingAllPayment, isErrorAllPayment, isSuccessAllPayment, isMessageAllPayment} = useSelector(allPayments)
  const { AllUsers, isSuccessAllUsers, isErrorAllUsers, isMessageAllUsers } = useSelector(allUsers)

  useEffect(()=> {
    dispatch(getAllPayment())
    dispatch(getAllUsers())
  }, [])

  useEffect(()=> {
    if(isMessageAllPayment === 'token expired' || isMessageAllPayment === "Token is invalid"){
      toast.error('Error!! Please Login')
      dispatch(logout())
    } else if(isMessageAllPayment !== '') {
      toast.error(isMessageAllPayment)
    }

    if(isSuccessAllPayment || isErrorAllPayment){
      dispatch(resetAllPayment())
    }
    

  }, [isSuccessAllPayment, isErrorAllPayment])

  useEffect(()=> {
    if(isMessageAllUsers != ''){
      toast.error(isMessageAllUsers)
    }
    if(isSuccessAllUsers || isErrorAllUsers){
      dispatch(resetAllUsers())
    }
  }, [isErrorAllUsers, isSuccessAllUsers])

  const filteredData = allPayment?.filter((data)=> {
    const searchText = `${data?.paymentDetails?.user} ${data?.user} ${data?.paymentDetails?.paymentType} ${data?.paymentType} ${data?.paymentDetails?.amount} ${data?.amount}`.toLowerCase()
    return searchText.includes(globalFilterValue.toLowerCase())
  })

  const dateFormat = (date) => {
    const dateFromMongoDB = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour clock format
    };

    const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

    return formatted
}

const dateFormatTemplate = (paymentDetails)=> {
    return dateFormat(paymentDetails?.createdAt)
}

  const usernameTemplate = (userId)=> {
    const username = AllUsers?.find((user)=> user._id === userId?.paymentDetails?.user || user._id === userId?.user)
    return username?.username
  }

  const transactionTypeTemplate =(data)=> {
    return data?.paymentDetails?.paymentType?.toUpperCase() || data?.paymentType?.toUpperCase()
  }

  const amountFormat = (paymentDetails)=> {
    if(paymentDetails?.paymentDetails){
      return '₦' + paymentDetails?.paymentDetails?.amount
    } else {
      return '₦' + paymentDetails?.amount
    }
  }

  const statusBodyTemplate = (status) => {
    return <Tag value={status?.paymentDetails?.status || status?.status} severity={getSeverity(status)}></Tag>;
  };

  const getSeverity = (status)=> {
    switch(status?.paymentDetails?.status || status?.status){
       case 'completed':
            return 'success';
  
        case 'pending':
            return 'warning';

        case 'failed':
            return 'danger';
  
        default:
            return null;
    }
  }

  const selectChange = (e)=> {
    navigate(`/user/all-payments-details/${e._id}`)
  }

  return (
    <>
      {/* <Header /> */}
      <Helmet defer={false}>
        <title>Fliprecharge || All Payments</title>
      </Helmet>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{height : "70vh"}}></div>
      {/* Page content */}
      <Container className="" style={{marginTop: "-22rem"}} fluid>
        {/* Table */}
        <Row>
        {isLoadingAllPayment && <Spinner />}
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">All Payments</h3>
              </CardHeader>
              <div>
              <InputText placeholder='username, amount or transaction type' className='searchdrop ml-2 p-2 border w-50 my-2'  value={globalFilterValue}
            onChange={(e) => setGlobalFilterValue(e.target.value)} />
                <DataTable value={filteredData} responsiveLayout='scroll' selectionMode="single" selection={selectedPayments} onSelectionChange={(e) => selectChange(e.value)}  paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                  <Column field='createdAt' header='Date' body={dateFormatTemplate} ></Column>
                  <Column field='' header='User' body={usernameTemplate}></Column>
                  <Column field='' header='Transaction Type' body={transactionTypeTemplate}></Column>
                  <Column field='' header='Amount' body={amountFormat}></Column>
                  <Column field='' header='Status' body={statusBodyTemplate}></Column>
                </DataTable>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default All_Payments;
