import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { baseApiUrl } from '../../../Utils/constants'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Tag } from 'primereact/tag'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Card, CardHeader, Container } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { airtimeTransactions } from '../../../features/AirtimeTransaction/AirtimeTransactionSlice'
import { getUser, logout } from '../../../features/Auth/AuthSlice'
import Spinner from '../../../Component/Spinner'
import axios from 'axios'
import { DatePicker } from 'antd'
import * as XLSX from 'xlsx';

const AdminAirtimeTransaction = () => {
  const { RangePicker } = DatePicker
  const dispatch = useDispatch()
  const [dates, setDates] = useState([])
  const [tableValue, setTableValue] = useState([])
  const [loading, setLoading] = useState(false)
  const { user } = useSelector(getUser)
  const [searchLoading, setSearchLoading] = useState(false)
  const [adminAirtimeTransaction, setAdminAirtimeTransaction] = useState([])

  const { airtimeTransactionNetwork } = useSelector(airtimeTransactions)
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"
  const nairaSign = '₦'
  const transaction_complete = 'completed'
  const transaction_pending = 'pending'
  const transaction_failed = 'failed'
  const transaction_processing = 'processing'
  const searchAirtimeTransactionByDatesApi = "/api/airtime/get-all-airtime-transactions-by-dates"
  const gloProductCode = 'GLOVTU'
  const mtnProductCode = 'MTNVTU'
  const airtelProductCode = 'AIRTELVTU'
  const nineMobilePCode = '9MOBILEVTU'
  const mtnAirtimeNetwork = '/admin-mtn-airtime'
  const gloAirtimeNetwork = '/admin-glo-airtime'
  const airtelAirtimeNetwork = '/admin-airtel-airtime'
  const nineMobileAirtimeNetwork = '/admin-9mobile-airtime'
  const getAllAirtimeTransactionsApi = '/api/airtime/get-all-airtime-transactions'


  const userFormat = (airtimeTransaction) => {
    return airtimeTransaction.fullname || airtimeTransaction.username
  }

  const amountFormat = (airtimeTransaction) => {
    return nairaSign + airtimeTransaction?.amount
  }

  const airtimeFormat = (airtimeTransaction) => {
    if (airtimeTransaction?.airtime === undefined) {
      return nairaSign + 0

    }
    else {
      return nairaSign + airtimeTransaction?.airtime
    }
  }

  const dateFormat = (date) => {
    const dateFromMongoDB = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour clock format
    };

    const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

    return formatted
  }

  const dateFormatTemplate = (airtimeTransaction) => {
    return dateFormat(airtimeTransaction?.createdAt)
  }

  const statusBodyTemplate = (airtimeTransaction) => {
    return <Tag value={airtimeTransaction.status} severity={getSeverity(airtimeTransaction)}></Tag>;
  };

  const getSeverity = (airtimeTransaction) => {
    switch (airtimeTransaction.status) {
      case transaction_complete:
        return 'success';

      case transaction_pending:
        return 'warning';

      case transaction_processing:
        return 'warning';

      case transaction_failed:
        return 'danger';

      default:
        return null;
    }
  };

  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const totalAmount = tableValue?.filter((transaction) => transaction.status === transaction_complete)?.reduce((sum, transaction) => sum + transaction?.amount, 0)
  const totalAirtime = tableValue?.filter((transaction) => transaction.status === transaction_complete)?.reduce((sum, transaction) => transaction?.airtime !== undefined ? sum + transaction?.airtime : sum, 0);

  //To get All data by dates

  const searchDates = async () => {
    if (dates === null) {
      toast.error('Please Select dates')
    } else {
      const formData = { startDate: dates[0], endDate: dates[1] }
      try {
        const headers = {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'

        };
        setSearchLoading(true)
        const response = await axios.post(baseApiUrl + searchAirtimeTransactionByDatesApi, formData, { headers })
        if (response.data) {
          setAdminAirtimeTransaction(response.data)
        }
      } catch (error) {
        if (error.response.data !== errorTokenExpired) {
          toast.error(error.response.data)
        } else {
          dispatch(logout())
          toast.error('Error!! Please Login')
        }
      } finally {
        setSearchLoading(false)
      }
    }
  }
  const exportToExcel = () => {
    // Create a workbook
    const wb = XLSX.utils.book_new();

    // Convert table data to worksheet
    const ws = XLSX.utils.json_to_sheet(tableValue.map(item => ({
      Date: dateFormat(item.createdAt),
      User: userFormat(item),
      Airtime: airtimeFormat(item),
      Amount: amountFormat(item).replace(nairaSign, ''), // Remove the currency symbol for numeric values
      'Mobile Number': item.msisdn,
      Status: item.status
    })));

    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "AirtimeTransactions");

    // Download the workbook
    XLSX.writeFile(wb, "AirtimeTransactions.xlsx");
  };


  useEffect(() => {
    if (airtimeTransactionNetwork === gloAirtimeNetwork) {
      const airtimetranx = adminAirtimeTransaction?.filter(data => data.productCode === gloProductCode)
      setTableValue(airtimetranx)
    }
    if (airtimeTransactionNetwork === mtnAirtimeNetwork) {
      const airtimetranx = adminAirtimeTransaction?.filter(data => data.productCode === mtnProductCode)
      setTableValue(airtimetranx)
    }
    if (airtimeTransactionNetwork === airtelAirtimeNetwork) {
      const airtimetranx = adminAirtimeTransaction?.filter(data => data.productCode === airtelProductCode)
      setTableValue(airtimetranx)
    }
    if (airtimeTransactionNetwork === nineMobileAirtimeNetwork) {
      const airtimetranx = adminAirtimeTransaction?.filter(data => data.productCode === nineMobilePCode)
      setTableValue(airtimetranx)
    }
  }, [adminAirtimeTransaction])

  //Fetch all data Transactions

  useEffect(() => {
    const getAllAirtimeTranxactions = async () => {
      try {
        setLoading(true)
        const headers = {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'

        };
        const response = await axios.get(baseApiUrl + getAllAirtimeTransactionsApi, { headers })
        if (response.data) {
          let airtimeTransaction = response.data
          if (airtimeTransactionNetwork === gloAirtimeNetwork) {
            const airtimetranx = airtimeTransaction?.filter(data => data.productCode === gloProductCode)
            setTableValue(airtimetranx)
          }
          if (airtimeTransactionNetwork === mtnAirtimeNetwork) {
            const airtimetranx = airtimeTransaction?.filter(data => data.productCode === mtnProductCode)
            setTableValue(airtimetranx)
          }
          if (airtimeTransactionNetwork === airtelAirtimeNetwork) {
            const airtimetranx = airtimeTransaction?.filter(data => data.productCode === airtelProductCode)
            setTableValue(airtimetranx)
          }
          if (airtimeTransactionNetwork === nineMobileAirtimeNetwork) {
            const airtimetranx = airtimeTransaction?.filter(data => data.productCode === nineMobilePCode)
            setTableValue(airtimetranx)
          }
        }
      } catch (error) {
        if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
          toast.error('Error!! Please Login again')
          dispatch(logout())
        } else if (error.response) {
          toast.error(error.response.data)
        } else {
          toast.error(error)
        }
      } finally {
        setLoading(false)
      }
    }
    getAllAirtimeTranxactions()

  }, [airtimeTransactionNetwork])

  return (
    <div>
      <Helmet defer={false}>
        <title>Fliprecharge || Airtime Transactions</title>
      </Helmet>
      {searchLoading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        <Card className="shadow px-3 pb-4">
          <CardHeader className="bg-transparent">
            <h3 className="mb-0">Airtime Transactions</h3>
          </CardHeader>
          <div className='d-md-flex justify-content-between mt-5'>
            <RangePicker
              className='w-75 w-md-100 my-2 me-5'
              onChange={(values) =>
                setDates(values?.map(item =>
                  item.format("YYYY-MM-DD")
                ))
              }
            />
            <button onClick={searchDates} disabled={!dates} className='btn btn-primary w-25 my-2' style={{ minWidth: "100px" }} type='submit'>Search</button>
            <button onClick={exportToExcel} className="btn btn-success my-2">Export to Excel</button>

          </div>
          <div className='d-md-flex mt-2 text-center'>
            <div className='alert alert-primary fs-5'>Total Airtime: ₦{totalAirtime.toFixed(2)}</div>
            <div className='alert alert-primary mx-md-4 fs-5'>Total Amount: ₦{totalAmount.toFixed(2)}</div>
          </div>
          {loading ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search' className='searchdrop p-2 border w-50 my-2' onInput={(e) => setFilter({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS }, })} />
            <DataTable value={tableValue} filters={filter} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
              <Column field='createdAt' header='Date' body={dateFormatTemplate} style={{ width: '30%' }}></Column>
              <Column field='fullname' header='User' body={userFormat} style={{ width: '30%' }}></Column>
              <Column field='airtime' header='Airtime' body={airtimeFormat}></Column>
              <Column field='amount' header='Amount' body={amountFormat}></Column>
              <Column field='msisdn' header='Mobile Number'></Column>
              <Column field='status' header='Status' body={statusBodyTemplate}></Column>
            </DataTable></div>}
        </Card>
      </Container>
    </div>
  )
}

export default AdminAirtimeTransaction