import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Home from './Pages/Home';
import { HelmetProvider } from 'react-helmet-async'
import Admin from './UserDashboard/layouts/Admin';
import Auth from './UserDashboard/layouts/Auth';
import ReactGA from 'react-ga';
ReactGA.initialize('G-WNDLL3WQNN');


function App() {
  const helmetContext = {}
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/auth/*' element={<Auth />} />
          <Route path='/user/*' element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
