import axios from "axios";
import { baseApiUrl } from "../../Utils/constants";

const API = `${baseApiUrl}/api/airtime`

const findAllAirtimeTransaction = async(accesstoken, userId)=> {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`
        }
    }
    const response = await axios.get(`${API}/get-airtime-transactions/${userId}`, config)
    return response.data
}

const AirtimeTransactionService = {
    findAllAirtimeTransaction
}

export default AirtimeTransactionService