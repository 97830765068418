import React, { useState } from 'react'
import Image1 from  '../Images/Whyus1.avif'
import Image2 from  '../Images/Whyus2.avif'
import Image3 from  '../Images/Whyus3.avif'


const WhyUS = () => {

  return (
    <div id='Whyus' className='py-4'>
        <h1 className='whyusheader py-3 text-center' style={{fontSize : "60px", color: " #1171ef"}}>What makes us stand out?</h1>
        {/* left-side */}
        <div className='whyusmsg w-75 mx-auto'>
        <p className='text-center' style={{fontSize: "22px"}}>Our services include instant recharge options for airtime, data bundles, electricity bill payment, and more. We pride ourselves on being fast, providing automated services, offering 100% secure transactions, and being a reliable choice. Our services are available online 24/7, and we also provide customer support around the clock.</p>
        </div>
        {/* right-side */}
        <div className='whyusimg d-flex px-2 py-3'>
            <img className='col-4 px-3' src={Image1} alt="" />
            <img className='col-4 px-3' src={Image2} alt="" />
            <img className='col-4 px-3' src={Image3} alt="" />
        </div>
    </div>
  )
}

export default WhyUS