import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { baseApiUrl } from '../../../Utils/constants'
import { getUser, logout } from "../../../features/Auth/AuthSlice.jsx";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardBody, Container, Row } from "reactstrap";
import { Button, Form, Input, Select, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'
import { toast } from "react-toastify";
import { updateWalletBalance, walletBalance } from "../../../features/Wallet/WalletSlice.jsx";
import Spinner from "../../../Component/Spinner.jsx";
import ReactGA from 'react-ga';
import { json } from 'react-router-dom';

const BuyDataBundle = () => {
  const [data, setData] = useState([])
  const { user } = useSelector(getUser)
  const { balance } = useSelector(walletBalance)
  const [network, setNetwork] = useState('')
  const [networkValue, setNetworkValue] = useState('')
  const [planDescription, setPlanDescription] = useState('')
  const [calculatedAmount, setCalculatedAmount] = useState()
  const [dataCode, setDataCode] = useState()
  const [bundleValue, setBundleValue] = useState()
  const [dataBundles, setDataBundles] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState({});
  const [dataAmount, setDataAmount] = useState()
  const [amount, setAmount] = useState()
  const [Loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"
  const dataBundleApi = '/api/data/get-sonite-data-bundle/'
  const userCommissionApi = '/api/airtime-commission/get-commission-by-network/'
  const soniteDataApi = '/api/data/buy-sonite-data-bundle'
  const mtnDataNetwork = 'MTNDATA'
  const gloDataNetwork = 'GLODATA'
  const nineMobileDataNetwork = '9MOBILEDATA'
  const airtelDataNetwork = 'AIRTELDATA'
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const networkOnChange = (value) => {
    setNetworkValue(value)
    getDataBundle(value)
  }
  const getDataBundle = async (value) => {
    setLoading(true)
    form.resetFields(["dataCode"])

    const data = {
      productCode: value
    }
    try {
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${baseApiUrl}${dataBundleApi}`, data, { headers })
      setDataBundles(response.data);
    } catch (error) {
      if (error) {
        if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
          toast.error('Error!! Please Login again')
          dispatch(logout())
        } else if (error.response.data) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error)
        }
      }

    } finally {

      setLoading(false)
    }
  }
  const handleChange = (selectedValue) => {
    const selectedOption = dataBundles.find(bundle => bundle.data_code === selectedValue);
    setSelectedBundle(selectedOption);
    setDataAmount(selectedOption.amount)
    fetchCommissionForUser(selectedOption);
    setDataCode(selectedOption.data_code)
    setBundleValue(selectedOption.value)
    setPlanDescription(`${selectedOption.description} - ${selectedOption.duration}`)
  };
  const fetchCommissionForUser = async (selectedOption) => {
    try {
      setLoading(true)
      let networkparamsindex = networkValue.indexOf("DATA")
      let networkparams = networkValue.substring(0, networkparamsindex).toLowerCase();
      setNetwork(networkparams)
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'
      }
      const response = await axios.get(`${baseApiUrl}${userCommissionApi}${networkparams}`, { headers })
      let amountToDeduct = response.data.commission / 100 * selectedOption.amount
      setCalculatedAmount(selectedOption.amount - amountToDeduct)

    } catch (error) {
      console.log(" error " + error)
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (error.response.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    }
    finally {
      setLoading(false)
    }
  }
  const onFinish = async (e) => {
    setDisable(true)
    setLoading(true)
    if (!calculatedAmount) {
      toast.info('No amount!! Please input data amount')
      form.resetFields()
      setLoading(false)
      setDisable(false)
      setSelectedBundle()
      setCalculatedAmount()
    } else if (calculatedAmount > balance) {
      toast.error('Insufficient Funds in Wallet')
      form.resetFields()
      setLoading(false)
      setDisable(false)
      setSelectedBundle()
      setCalculatedAmount()
    } else if (e.msisdn[0] === '0' && e.msisdn.length === 11) {
      const FormData = {
        productCode: e.productCode,
        dataCode: dataCode,
        network: network,
        msisdn: e.msisdn,
        amount: calculatedAmount,
        value: bundleValue,
        planName: `${e.productCode} - ${bundleValue}`,
        dataamount: dataAmount,
        planDescription: planDescription
      }

      BuySoniteData(FormData)
    } else if (e.msisdn.length !== 11) {
      toast.info('Mobile Number is Incomplete')
      form.resetFields()
      setLoading(false)
      setDisable(false)
      setSelectedBundle()
      setCalculatedAmount()
    } else {
      toast.info('Mobile Number Format is incorrect')
      form.resetFields()
      setLoading(false)
      setDisable(false)
      setSelectedBundle()
      setCalculatedAmount()
    }
  }
  const BuySoniteData = async (data) => {
    // console.log("data " + JSON.stringify(data))
    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on Buy Now',
      label: 'Buy Data',
    });
    const headers = {
      'Authorization': `Bearer ${user?.accessToken}`,
      'Content-Type': 'application/json'
    }

    try {
      const response = await axios.post(`${baseApiUrl}${soniteDataApi}`, data, { headers })
      const { success, message, walletBalance } = response.data
      if (success === true) {
        toast.success(message)
        dispatch(updateWalletBalance(walletBalance))
      } else {
        toast.error(message)
      }
    }
    catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (error.response.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    }

    finally {
      form.resetFields()
      // see where the problem is, values set for manipulation wont reset with form.resetFields() 
      setCalculatedAmount()
      setDataCode()
      setSelectedBundle();
      setAmount()
      setLoading(false)
      setDisable(false)
    }
  }


  useEffect(() => {
    if (network) {


    }
  }, [network])

  return (
    <div>
      <Helmet defer={false}>
        <title>Fliprecharge || Buy Data Bundle</title>
      </Helmet>
      {Loading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Buy Bundle Data </h3>
              </CardHeader>
              <CardBody>
                <Form form={form} className='w-100 bg-white p-4 rounded-xl' onFinish={onFinish}>
                  <label htmlFor="">Network</label>
                  <Form.Item className="w-100" name="productCode" rules={[{ required: 'true', message: "Please select a network" }]}>
                    <Select value={network} onChange={networkOnChange}>
                      <Select.Option value={mtnDataNetwork}>MTN</Select.Option>
                      <Select.Option value={nineMobileDataNetwork}>9Mobile</Select.Option>
                      <Select.Option value={gloDataNetwork}>Glo</Select.Option>
                      <Select.Option value={airtelDataNetwork}>Airtel</Select.Option>
                    </Select>
                  </Form.Item>
                  <label htmlFor="">Select Bundle</label>
                  <Form.Item className="w-100" name="dataCode" rules={[{ required: 'true', message: "Please select bundle" }]}>
                    <Select onChange={handleChange} value={dataCode}>
                      {dataBundles.map((bundle, index) => (
                        <Select.Option key={index} value={bundle.data_code}>
                          {`${bundle.value} - ${bundle.product_code}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedBundle?.description && selectedBundle?.duration && (
                    <div style={{ background: '#89CFF0', padding: 10, borderRadius: 8, marginTop: 15 }}>
                      <h4>Description: {`${selectedBundle.description} - ${selectedBundle.duration} (${selectedBundle.amount})`}</h4>
                    </div>
                  )}
                  <label htmlFor="">Amount</label>
                  <Form.Item className="w-100" rules={[{ required: 'true', message: "Please input amount" }]}>
                    <Input value={calculatedAmount} name="amount" disabled type='number' />
                  </Form.Item>
                  <label htmlFor="">Mobile Number</label>
                  <Form.Item className="w-100" name="msisdn" rules={[{ required: 'true', message: "Please input your Mobile Number" }]}>
                    <Input maxLength={11}
                      suffix={
                        <Tooltip title="Eg: 0812345678">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button className="bg-gradient-info text-white d-flex justify-content-center align-items-center my-3 px-4 py-3 rounded-lg" htmlType="submit" disabled={disable}>Buy now</Button>
                  </Form.Item>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>

  )
}

export default BuyDataBundle