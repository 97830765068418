import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Tag } from 'primereact/tag'
import { Helmet } from 'react-helmet-async'
import { Card, CardHeader, Container, Row } from 'reactstrap'
import { getUser, logout } from '../../../features/Auth/AuthSlice'
import { baseApiUrl } from '../../../Utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { allUsers, getAllUsers, resetAllUsers } from '../../../features/Users/UsersSlice'
import { dataTransactions } from '../../../features/DataTransaction/DataTransactionSlice'
import Spinner from '../../../Component/Spinner'
import { DatePicker } from 'antd'
import * as XLSX from 'xlsx';

const AdminDataTransaction = () => {
  const { RangePicker } = DatePicker
  const dispatch = useDispatch()
  const { user } = useSelector(getUser)
  const { AllUsers } = useSelector(allUsers)
  const [loading, setLoading] = useState(false)
  const [tableValue, setTableValue] = useState([])
  const [dates, setDates] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)
  const [adminDataTransaction, setAdminDataTransaction] = useState([])
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"


  const { dataTransactionNetwork } = useSelector(dataTransactions)

  //Table template format
  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const getUsername = (userId) => {

    const User = AllUsers?.find(username => username?._id === userId?.user)
    return User?.username
  }


  const getReportUsername = (userId) => {

    const User = AllUsers?.find(username => username?._id === userId)
    return User?.username
  }

  const amountDataPlanFormat = (dataTransaction) => {
    if (dataTransaction.amount === undefined) {
      return '₦' + dataTransaction?.dataPlan?.amount
    } else {
      return '₦' + dataTransaction?.amount
    }
  }


  const dataPlanFormat = (dataTransaction) => {
    if (dataTransaction?.dataPlan.type === "sme") {
      if (dataTransaction?.dataPlan?.validity > 1) {
        return dataTransaction?.dataPlan?.volume + dataTransaction?.dataPlan?.unit + ' for ' + dataTransaction?.dataPlan?.validity + 'days'
      } else {
        return dataTransaction?.dataPlan?.volume + dataTransaction?.dataPlan?.unit + ' for ' + dataTransaction?.dataPlan?.validity + 'day'
      }
    }
    else {
      return dataTransaction?.dataPlan.planDescription
    }
  }
  const dataTypeFormat = (dataTransaction) => {
    if (dataTransaction?.dataPlan.type) {
      return dataTransaction?.dataPlan.type
    }
  }

  const dateFormat = (date) => {
    const dateFromMongoDB = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour clock format
    };

    const formatted = new Intl.DateTimeFormat('en-US', options).format(dateFromMongoDB);

    return formatted
  }

  const dateFormatTemplate = (dataTransaction) => {
    return dateFormat(dataTransaction?.createdAt)
  }

  const numberFormat = (number) => {
    if (number?.startsWith("234")) {
      return '0' + number?.substring(3)
    } else {
      return number
    }
  }



  const numberFormatTemplate = (dataTransaction) => {
    return numberFormat(dataTransaction?.msisdn)
  }

  const statusBodyTemplate = (dataTransaction) => {
    return <Tag value={dataTransaction.status} severity={getSeverity(dataTransaction)}></Tag>;
  };

  const getSeverity = (dataTransaction) => {
    switch (dataTransaction.status) {
      case 'completed':
        return 'success';

      case 'pending':
        return 'warning';

      case 'failed':
        return 'danger';

      default:
        return null;
    }
  };

  // calculate total data
  const totalData = tableValue?.filter((transaction) => transaction.status === 'completed')?.reduce((sum, transaction) => {
    const { volume, unit } = transaction.dataPlan

    let standardVolume = volume
    if (unit === 'mb') {
      standardVolume /= 1024
    }

    return sum + standardVolume
  }, 0)


  //calculate total amount
  const totalAmount = tableValue?.filter((transaction) => transaction.status === 'completed')?.reduce((sum, transaction) => transaction.amount === undefined ? sum + transaction.dataPlan.amount : sum + transaction.amount, 0)


  //To get All data by dates

  const searchDates = async () => {
    if (dates === null) {
      toast.error('Please Select dates')
    } else {
      const formData = { startDate: dates[0], endDate: dates[1] }
      try {
        const headers = {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'

        };
        setSearchLoading(true)
        const response = await axios.post(baseApiUrl + "/api/data/get-all-data-transactions-by-dates", formData, { headers })
        if (response.data) {
          setAdminDataTransaction(response.data)
        }
      } catch (error) {
        if (error.response.data !== 'token expired') {
          toast.error(error.response.data)
        } else {
          dispatch(logout())
          toast.error('Error!! Please Login')
        }
      } finally {
        setSearchLoading(false)
      }
    }
  }
  const exportToExcel = () => {
 
    const ws = XLSX.utils.json_to_sheet(tableValue.map(transaction => ({

      Date: dateFormat(transaction.createdAt),
      User: getReportUsername(transaction.user),
      'Data Plan': dataPlanFormat(transaction),
      'Data Type': dataTypeFormat(transaction),
      Amount: amountDataPlanFormat(transaction),
      'Mobile Number': numberFormat(transaction.msisdn),
      Status: transaction.status,
    })));

   

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "DataTransactions");
    XLSX.writeFile(wb, "DataTransactions.xlsx");
  };
  useEffect(() => {
    if (dataTransactionNetwork === '/admin-glo-transactions') {
      const datatranx = adminDataTransaction?.filter(data => data.dataPlan.network === 'glo')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === '/admin-mtn-transactions') {
      const datatranx = adminDataTransaction?.filter(data => data.dataPlan.network === 'mtn')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === '/admin-airtel-transactions') {
      const datatranx = adminDataTransaction?.filter(data => data.dataPlan.network === 'airtel')
      setTableValue(datatranx)
    }
    if (dataTransactionNetwork === '/admin-9mobile-transactions') {
      const datatranx = adminDataTransaction?.filter(data => data.dataPlan.network === '9mobile')
      setTableValue(datatranx)
    }
  }, [adminDataTransaction])


  //Fetch all data Transactions

  useEffect(() => {
    dispatch(getAllUsers())
    const getAllDataTranxactions = async () => {
      try {
        setLoading(true)
        const headers = {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'

        };
        const response = await axios.get(baseApiUrl + '/api/data/get-all-data-transactions', { headers })
        if (response.data) {
          let dataTransaction = response.data
          if (dataTransactionNetwork === '/admin-glo-transactions') {
            const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'glo')
            setTableValue(datatranx)
          }
          if (dataTransactionNetwork === '/admin-mtn-transactions') {
            const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'mtn')
            setTableValue(datatranx)
          }
          if (dataTransactionNetwork === '/admin-airtel-transactions') {
            const datatranx = dataTransaction?.filter(data => data.dataPlan.network === 'airtel')
            setTableValue(datatranx)
          }
          if (dataTransactionNetwork === '/admin-9mobile-transactions') {
            const datatranx = dataTransaction?.filter(data => data.dataPlan.network === '9mobile')
            setTableValue(datatranx)
          }
        }
      } catch (error) {
        if (error.response.data === 'token expired' || error.response.data === 'Token is invalid') {
          toast.error('Error!! Please Login again')
          dispatch(logout())
        } else if (error.response) {
          toast.error(error.response.data)
        } else {
          toast.error(error)
        }
      } finally {
        dispatch(resetAllUsers())
        setLoading(false)
      }
    }
    getAllDataTranxactions()

  }, [dataTransactionNetwork])


  return (
    <div> {/* <Header /> */}
      <Helmet defer={false}>
        <title>Fliprecharge || All Data Transactions</title>
      </Helmet>
      {searchLoading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow px-3 pb-4">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">All Data Transactions</h3>
              </CardHeader>
              <div className='d-md-flex justify-content-between mt-5'>
                <RangePicker
                  className='w-75 w-md-100 my-2 me-5'
                  onChange={(values) =>
                    setDates(values?.map(item =>
                      item.format("YYYY-MM-DD")
                    ))
                  }
                />
                <button onClick={searchDates} disabled={!dates} className='btn btn-primary w-25 my-2' style={{ minWidth: "100px" }} type='submit'>Search</button>
                <button className="btn btn-success my-2" onClick={exportToExcel}>Export to Excel</button>


              </div>
              <div className='d-md-flex mt-2 text-center'>
                <div className='alert alert-primary fs-5'>Total Data: {totalData.toFixed(2) > 1024 ? (totalData / 1024).toFixed(2) : totalData.toFixed(2)}{totalData.toFixed(2) > 1024 ? 'TB' : 'GB'}</div>
                <div className='alert alert-primary mx-md-4 fs-5'>Total Amount: ₦{totalAmount.toFixed(2)}</div>
              </div>
              <div>
                {loading ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search' className='searchdrop p-2 border w-25 my-4' onInput={(e) => setFilter({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS }, })} />
                  <DataTable value={tableValue} filters={filter} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                    <Column header='Date' field='createdAt' body={dateFormatTemplate} ></Column>
                    <Column header='User' field='user' body={getUsername} ></Column>
                    <Column field='dataPlan' header='Data Plan' body={dataPlanFormat} style={{ width: '30%' }}></Column>
                    <Column field='dataPlan' header='Data Type' body={dataTypeFormat} style={{ width: '30%' }}></Column>
                    <Column field='dataPlan' header='Amount' body={amountDataPlanFormat}></Column>
                    <Column header='Amount' field='dataPlan' body={amountDataPlanFormat}></Column>
                    <Column header='Mobile Number' field='msisdn' body={numberFormatTemplate}></Column>
                    <Column header='Status' field='status' body={statusBodyTemplate}></Column>
                  </DataTable></div>}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default AdminDataTransaction