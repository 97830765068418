import axios from "axios"
import { baseApiUrl } from "../../Utils/constants";


const API = `${baseApiUrl}/api/payment`

const findUserPayments = async(accesstoken)=> {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`
        }
    }
    const response = await axios.get(`${API}/user-payments`, config)
    return response.data
}

const findAllPayment = async(accesstoken)=>{
 const config = {
     headers: {
        Authorization: `Bearer ${accesstoken}`
     }
 }
 const response = await axios.get(`${API}/all-payments`, config)
 return response.data
}


const PaymentService = {
    findUserPayments,
    findAllPayment
}

export default PaymentService